<template>
  <div class="home">
    <div class="gray-bg">
      <div class="hero">
        <div class="container h-100 content-overlay">
          <div class="row align-items-center w-100 h-100">
            <div class="col-md-6">
              <h1 class="wow fadeInUp" data-wow-delay="0s">Hire remote workers & freelancers easily and with confidence</h1>      
              <h5 class="wow fadeInUp" data-wow-delay="0.2s">
                We’re here to help you hire remote workers <br> to take care of your business 24/7.</h5>
              <el-button @click="dialogVisible = true" type="success" class="is-round wow fadeInUp" data-wow-delay="0.4s" to="/employer/signup">Sign up now</el-button> 
            </div>
          </div>
        </div>
        <div class="gradient-overlay"></div>
      </div>
    </div>
    
    <el-dialog
      title="Signup on CitronWorks"
      :visible.sync="dialogVisible"
      width="500px"
      class="signup-modal"
      center>
       <span slot="title" class="dialog-title">
          <h4 class="orange-text bold">Signup</h4>
       </span>
       <span>
        <router-link to="/employer/signup"><el-button type="warning" class="w-100 mb-3">Sign up as an Employer</el-button></router-link>
      </span>
       <span>
        <router-link to="/freelancersignup"><el-button type="warning" plain class="w-100">Sign up as a Freelancer</el-button></router-link>
      </span>
      
    </el-dialog>
    <div id="about" class="about text-center container">
      <div class="row">
        <div class="col-md-12">
          <div class="about-content">
            <h2 class="wow fadeInUp poppins" data-wow-delay="0.1s">About <span style="color: #f89827;">C</span><span style="color: #f8a442;">i</span><span style="color: #faa61f;">t</span><span style="color: #fdcc1d;">r</span><span style="color: #fed132;">o</span><span style="color: #fecd23;">n</span>Works
            </h2>
            <h3 class="wow fadeInUp" data-wow-delay="0.2s" >CitronWorks is a global marketplace for freelance developers, virtual assistants, customer support staff, lead generation experts, copywriters, social media and blogging professionals, all available for one-off projects or ongoing work.
            </h3>
            <h3 class="wow fadeInUp" data-wow-delay="0.3s" >CitronWorks is free for the individual freelancer, the employers and agencies pay a small monthly fee, this membership can be paused at any time with full access to contractors already in your lists.</h3>
            <h3 class="wow fadeInUp" data-wow-delay="0.4s" >Our freelancers, agencies and employers come from all over the world, we are adding more professional categories every day.</h3>
          </div>
        </div>
      </div>
    </div>
    <div class="pitch container" id="pitch">
      <div class="row justify-content-md-center">
        <div class="col-lg-9">
          <div class="row employer-row">
            <div class="col-md-4 wow fadeIn" data-wow-delay="0.2s">
              <!-- <img src="/images/employer2.jpg" class="img-responsive"/> -->
              <picture >
                <source type="image/webp" data-srcset="/images/employer2.webp" class="img-responsive">
                <img data-src="/images/employer2.jpg" data-srcset="/images/employer2.jpg" src="/images/employer2.jpg" class="img-responsive">
              </picture>
            </div>
            <div class="col-md-8 wow fadeIn" data-wow-delay="0.2s">  
              <div class="pitch-content">
                <h1>For employers</h1>
                <p class="">
                  CitronWorks is subscription-based with no commissions and you get to keep your connections if you pause your membership. We have a trust score for both employers and freelancers so you can select with more transparency and also, build up a great reputation so you get the best talent knocking on your door…
                </p>
                <router-link to="/plans" tag="button" class="m-0 pointer read-more">See plans <i class="el-icon-d-arrow-right"></i></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-md-center">
        <div class="col-lg-9">
          <div class="row concierge-row">
            <div class="col-md-4 wow fadeIn" data-wow-delay="0.2s">
              <!-- <img src="/images/concierge2.jpg" class="img-responsive"/> -->
              <picture >
                <source type="image/webp" data-srcset="/images/concierge2.webp" class="img-responsive">
                <img data-src="/images/concierge2.jpg" data-srcset="/images/concierge2.jpg" src="/images/concierge2.jpg" class="img-responsive">
              </picture>
            </div>
            <div class="col-md-8 wow fadeIn" data-wow-delay="0.2s">  
              <div class="pitch-content">
                <h1>CitronWorks Concierge</h1>
                <p>
                  With CitronWorks Concierge, you can get the top freelancers and remote workers delivered right to your inbox. Just tell us what you’re looking for, and we’ll do all the work of finding, interviewing, and shortlisting the top candidates for you to choose from.
                </p>
                <router-link to="/concierge" tag="button" class="m-0 pointer read-more">Read more <i class="el-icon-d-arrow-right"></i></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-md-center">
        <div class="col-lg-9">
          <div class="row freelancer-row">
            <div class="col-md-4 wow fadeIn" data-wow-delay="0.2s">
              <!-- <img src="/images/freelancer2.jpg" class="img-responsive"/> -->
              <picture >
                <source type="image/webp" data-srcset="/images/freelancer2.webp" class="img-responsive">
                <img data-src="/images/freelancer2.jpg" data-srcset="/images/freelancer2.jpg" src="/images/freelancer2.jpg" class="img-responsive">
              </picture>
            </div>  
            <div class="col-md-8 wow fadeIn" data-wow-delay="0.2s">
              <div class="pitch-content">
                <h1>For freelancers</h1>
                <p class="">
                  We provide a mutually safe marketplace where both freelancers and employers have a trust score. It’s free to join and use for freelancers with some paid options that can get you ahead of the crowd. We hope to see you onboard!
                </p>
                <router-link to="/freelancers" tag="button" class="m-0 pointer read-more">Read more <i class="el-icon-d-arrow-right"></i></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="why-cw">
        <center>
          <img src="images/green-divider.png" width="90" height="auto" class="divider">
        </center>
        <h2 class="mb-4 orange-text text-center wow fadeInUp" data-wow-delay="0s">Why choose <span style="font-weight: 600;">CitronWorks?</span></h2>
        <el-row type="flex" class="justify-content-center">
          <el-col :span="24" :md="19" :lg="20" type="flex" class="special-box justify-content-center wow fadeInUp" data-wow-delay="0.2s">
            <el-card class="special-pointers">
              <el-row :gutter="20" type="flex" class="justify-content-center">
                <el-col :xs="24" :sm="5" :md="6" :lg="6" class="hidden-xs">
                  <img src="/images/pointers.png" class="w-100 d-block mx-auto" alt="">
                </el-col>
                <el-col :xs="24" :sm="18" :md="16" :lg="17">
                  <ul>
                    <li>No commissions, ever</li>
                    <li>Employers pay a monthly fee, and they can pause at any time</li>
                    <li>Employers can contact the freelancers outside the platform as they wish</li>
                    <li>No bidding, employers can just put up their job and set their desired rate</li>
                    <li>Employers have access to all our freelancers</li>
                  </ul>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { WOW } from 'wowjs'
// import ContactForm from '~/components/ContactForm'

export default {
  // components: {
  //   ContactForm,
  // },
  data () {
    return {
      dialogVisible: false,
    }
  },
  mounted () {
    new WOW().init()
  },
}
</script>

<style lang="scss" scoped>
.why-cw {
  padding-top: 0;
}
.why-cw .divider {
  margin-bottom: 40px;
}
.learn-btn.employer {
  // margin-left: 20px;
  color: #ffffff !important;
  background-color: #91C145;
  border-color: #91C145;
}
.learn-btn.employer:hover {
  background-color: #7bb125;
  border-color: #7bb125;
  transition: ease-in .2s;
}
.home {
  overflow: hidden;
}
.content-overlay{
    z-index: 3;
    position: relative;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row nowrap;
    width: 100%;
    height: 100%;
    margin: 0 auto;
}
.gradient-overlay {
  z-index: 0;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: block; 
  background-image: -webkit-gradient(linear,left top,right top,color-stop(30%,#ec8435),to(transparent,60%));
  background-image: linear-gradient(90deg,#ec8435 30%,transparent 60%);
}

.hero {
  height: 600px;
  padding: 70px 0; 
  background-image: url('/images/hero-cover.jpg'); 
  background-size: contain;
  background-repeat: no-repeat;
  box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.1);
  color: white;
  position: relative;

  h1 {
    font-size: 3rem;
    font-weight: 600;
    text-shadow: 2px 0 7px #bc5a10;
    margin-bottom: 20px;
  }

  h5 {
    font-size: 1.1rem !important;
    line-height: 1.6;
    font-weight: 400;
    letter-spacing: 0.2px;
    text-shadow: 2px 0 7px #bc5a10;
    margin-bottom: 20px;
  }
  button {
    // text-transform: uppercase;
    font-weight: 700;
    letter-spacing: .4px;
    border-radius: 30px;
    padding: 15px 23px;
    width: fit-content;
    font-size: 1.05rem;
    margin-top: 15px;
  }
}
.about {
  padding: 100px 0 100px 0;
  
}
.about-content {
  max-width: 800px;
  margin: 0 auto;
}
.about-content h2 {
  font-size: 38px;
  line-height: 1.2;
  color: #454545;
  font-weight: bold;
}
.about-content h3 {
  font-size: 1.25rem;
  line-height: 1.6;
  letter-spacing: .4px;
  color: #576874;
  margin: 20px 0 0 0;
}
.img-responsive {
  max-width: 100%;
}
.freelancer-row, .employer-row, .concierge-row, .citronworkers-row {
  padding: 20px 5px;
  background: rgba(233, 229, 225, 0.45);
  border-radius: 5px;
  margin-bottom: 30px;
}
.freelancer-row img, .employer-row img, .concierge-row img, .citronworkers-row img {
  border-radius: 5px;
}
.freelancer-row, .employer-row, .concierge-row {
 margin-bottom: 50px;
}
.pitch-content {
  position: relative;
  height: 100%;
}
.pitch-content h1 {
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.6;
  letter-spacing: .4px;
  color: #E87722;
  margin: 0 0 5px 0;
  text-transform: capitalize;
}
.citronworkers-row .pitch-content h1 {
  letter-spacing: .2px;
  line-height: 1.2;
}
.pitch-content p {
  font-size: .9rem;
  line-height: 1.6;
  color: #576874;
  letter-spacing: .4px;
}
.el-dialog__body .el-button{
    padding: 20px;
    border-radius: 0;
    font-size: 16px;
}
.pitch-content .read-more {
  color: #ffffff !important;
  background-color: #E87722;
  border: 1px solid #E87722;
  border-radius: 4px;
  padding: 8px 13px;
  float: right;
  font-weight: 400;
  line-height: 1.4;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  text-decoration: none;
}
.signup-form {
  padding: 25px 25px 30px 25px;
  background: #ffffffde;
  background: rgba(255, 255, 255, .5);
  -moz-border-radius: 4px; 
  -webkit-border-radius: 4px; 
  border-radius: 4px;
  border: 1px solid white;
}

.signup-form h1 {
  font-weight: 600;
  font-size: 1.5rem;
  color: #454545;
  letter-spacing: .5px;
  line-height: 1.6;
  text-transform: capitalize;
  text-align: center;
  margin: 15px 0 10px 0;
}

.signup-form h3{
    padding-bottom: 15px;
    font-size: 1rem;
    color: #505050;
}

.signup-form form textarea {
  height: 100px;
}

.signup-form form .input-error {
  border-color: #19b9e7;
}

.signup-form p {
  font-family: 'Open Sans', sans-serif;
  font-size: .75rem;
  color: #576874;
  margin: 0 0 5px 0;
    text-align: left;
}
.signup .btn-action.btn-round {
  background: #3333CC;
  border-color: #3333CC;
  color: #FFFFFF;
  font-size: 15px;
  padding: 12px 24px;
  font-weight: 600;
  text-transform: capitalize;    
  border: 2px solid #3333CC;
  border-radius: 30px;
}

.signup .btn-action.btn-round:hover, .signup .btn-action.btn-round:focus {
  background: transparent;
  color: #3333CC;
  border-color: #3333CC;
}

.signup .btn-action {
  background: #3333CC;
  border-color: #3333CC;
  color: #FFFFFF;
}

.signup .btn-action:hover {
  background: transparent;
  border-color: #3333CC;
  color: #3333CC;
}
.cta-sub {
  padding-top: 100px;
  padding-bottom: 100px;
  background: linear-gradient(to right, rgba(232, 119, 33, .1), rgba(23, 21, 20, 0.4)), url(/images/signup.jpg) no-repeat center right;
  background-size: cover;
}

.cta-inner {
  max-width: 500px;
  float: none;
}

.cta-sub .cta-inner h1 {
  font-size: 28px;
  color: #FFFFFF;
  font-weight: 400;
  line-height: 1.2;
  margin-top: 10px;
  margin-bottom: 0;
}

.cta-sub .cta-inner p {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  color: #FFFFFF;
  font-weight: 300;
  line-height: 1.4;
  margin-top: 20px;
  margin-bottom: 40px;
}
#pitch {
  margin-bottom: 80px;
}
@media (min-width: 768px) {
  .hero {
    background-position: center;
    background-size: cover;
  }
  .about {
    padding: 50px 30px;
  }
  .visible-xs {
    display: none;
  }
}
@media (min-width: 993px) {
  .hero {
    background-position: 0% 30%;
    background-size: cover;
  }
  .hero-content h1 {
      margin: 0 10px 20px 0;
  }
  .visible-sm {
    display: none;
  }
  .about {
    padding: 100px 0px;
  }
}
@media (min-width: 1200px) {
  .pitch-content .read-more { 
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .hero {
    background-position: right;
    background-size: 90%;
  }
}
@media (min-width: 1500px) {
  .hero {
    background-size: 70%;
    background-position: right 30%;
  }
}

@media (max-width: 1199px) {

}
@media (max-width: 992px) {
  .pitch-content p {
    font-size: .95rem;
  }
  .pitch-content h1 {
    font-size: 1.75rem;
  }
  .employer-row, .concierge-row, .citronworkers-row {
    margin: 0 10px 50px 10px;
  }
  .freelancer-row {
    margin: 0 10px 50px 10px;    
  }
  #pitch {
    margin-bottom: 10px;
  }
  .cta-sub {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .learn-btn.employer {
    margin-left: 0;
    margin-top: 10px;
  }
  .learn-btn {
    width: 80%;
  }
  .hero-content h1 {
    font-size: 34px;
  }
}
@media (max-width: 767px) {
  .hero {
    height: auto;
    background: none;
  }
  .gradient-overlay {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(30%, #ec8435), to(#ec8435bf));
    background-image: linear-gradient(0deg, #ec8435 30%, #ec8435bf);
  }
  .hero h5, .hero h1 {
    max-width: 450px;
  }
  .citronworkers-row .pitch-content h1 {
    letter-spacing: .4px;
    line-height: 1.4;
  }
  .pitch img {
    display: block;
    margin: 0 auto;
  }
  .visible-xs {
    display: block;
  }
  .hidden-xs {
    display: none;
  }
  .about {
    padding: 50px 25px;
    overflow: hidden;
  }
  .about-content h3 {
    font-size: 1.1rem;
  }
  .about-content h2 {
    font-size: 1.75rem;
  }
  .freelancer-row, .employer-row, .concierge-row, .citronworkers-row {
    padding: 25px 10px;
    margin: 0 10px 50px 10px;
  }
  .pitch-content h1 {
    margin-top: 10px;
    line-height: 1.4;
    font-size: 1.6rem;
  }
  .signup-form {
    margin: 0 15px;
  }
}
@media (max-width: 500px) {
  .hero {
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
@media (max-width: 375px) { 
  .hero-content h1 {
    font-size: 1.9rem;
    margin-bottom: 1rem;
  }
  .hero-img {
    padding: 0 30px;
  }
}
</style>
