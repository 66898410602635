import { Message } from 'element-ui'

export default error => {
  if (error.response) {
    Message.error({ message: error.response.data.message, duration: 5000, showClose: true })
  }
  console.log(error)
}

export const prompt = ({http_code, message}) => {
  if (http_code > 210) {
    Message.error({ message: message, duration: 5000, showClose: true })
  }
}