export default {
  /**
   * Getter Freelancer
   * @param state
   * @returns {{}|string}
   */
  getFreelancerInfo: state => state.freelancer.info,
  getFreelancerErrResp: state => state.freelancer.errorResp,
  getFreelancerSuccessResp: state => state.freelancer.successResp,
  getDetailAlert: state => state.freelancer.detailSuccess,
  getBioAlert: state => state.freelancer.bioSuccess,
  getPasswordAlert: state => state.freelancer.passwordSuccess,
  getFreelancerBillingSuccessResp: state => state.freelancer.billingError,
  // getFreelancerAddressErrorResp: state => state.freelancer.addressErrorResp,
  getFreelancerAddressSuccessResp: state => state.freelancer.addressSuccessResp,
  getFreelancerBillingErrResp: state => state.freelancer.billingResp,
  // getFreelancerAddressSuccessResp: state => state.freelancer.addressSuccess,
  getFreelancerAddressErrorResp: state => state.freelancer.addressError,
  getFreelancerProfileProgress: state => state.freelancer.progress,
  /**
   *
   * @param state
   * @returns {{}|string}
   */
  getEmployerInfo: state => state.employer,
  getEmployerSuccessResp: state => state.employerSuccess,
  getEmployerErrResp: state => state.employerErr,
  getEmployerCmpprofileErrResp: state => state.employerErrCmpProfileErr,
  getEmployerProfileActions: state => state.employerProfileActions,
  getEmployerBookmarkedFreelancers: state => state.employerBookmarkedFreelancers,
  /**
   * FreelancerCompnayInfo
   * @param state
   * @returns {{}}
   */
  getFreelancerCompanyInfo: state => state.freelancerCompany,
  getFreelancerCompanyErrResp: state => state.apiError,
  getFreelancerCompanySuccessResp: state => state.apiSuccess,

  /**
   * FreelancerJobs Info
   * @param state
   * @returns {{success: string, error: string}|{success: string, error: string}}
   */
  getJobsInfo: state => state.jobs,
  getJobsDetails: state => state.jobs.show,
  getJobSuccess: state => state.jobs.success,
  getJobsError: state => state.jobs.error,

  jobApplicationSuccess: state => state.jobApplicationSuccess,
  jobApplicationOnLoading: state => state.jobApplicationOnLoading,
  jobApplicationOnUploading: state => state.jobApplicationOnUploading,
  jobApplicationError: state => state.jobApplicationErr,

  getAppliedJobs: state => state.jobs.applied,
  getBookmarkedJobs: state => state.jobs.bookmarked,

  getApiErrorResponse: state => state.apiError,
  getApiSuccessResponse: state => state.apiSuccess,
  disable: state => state.disable,
  enable: state => state.disable,
  agreement: state => state.agreement,
  isAuthenticated: state => !!state.auth,
  authStatus: state => state.status,
  authError: state => state.authError,

  setCategory: state => state.category,
  getUserSkill: function(state) {
    return state.userskill;
  },

  getPresentationSuccess: state => state.presentation,
  getPresentationErr: state => state.presentation.error,

  getCountry: state => state.country,

  getContactErr: state => state.contact.error,
  getContact: state => state.contact.success,

  getConciergeErr: state => state.conciergeError,
  getConcierge: state => state.concierge,

  getCitronworkersErr: state => state.citronworkersError,
  getCitronworkers: state => state.citronworkers,
  getCitronworkersMessage: state => state.citronworkers.successMessage,
  getAttachment: state => state.applicationAttachment,

  getFreeSubscriptionsCount: state => state.freeSubscriptionCount,

  getVerifyEmailResponse: state => state.emailVerifyResp,
  getVerifyEmailError: state => state.emailVerifyErr,

  getInviteRequestResponse: state => state.inviteUserResp,
  getInviteRequestError: state => state.inviteUserErr,
  getInviteRequestForm: state => state.inviteUserForm,
};
