<template>
  <el-form ref="form" :model="contactForm" class="contact-form">
    <el-row :gutter="15">
      <el-col :xs="24" :md="12">
        <el-form-item label="First Name" prop="first_name"
          :rules="[
            { required: true, message: 'First name is required'},
          ]" :show-message="false">
          <el-input v-model="contactForm.first_name"></el-input>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :md="12">
        <el-form-item label="Last Name" prop="last_name"
          :rules="[
            { required: true, message: 'Last name is required'},
          ]" :show-message="false">
          <el-input v-model="contactForm.last_name"></el-input>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :md="24">
        <el-form-item label="Email Address" prop="email"
          :rules="[
            { required: true, message: 'Email address is required'},
            { type: 'email', message: 'Please input correct email address'}
          ]" :show-message="false">
          <el-input v-model="contactForm.email"></el-input>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :md="24">
        <el-form-item label="Subject" prop="subject"
          :rules="[
            { required: true, message: 'Subject is required'},
          ]" :show-message="false">
          <el-input v-model="contactForm.subject"></el-input>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :md="24">
        <el-form-item label="Message" prop="message"
          :rules="[
            { required: true, message: 'Mesage is required'},
          ]" :show-message="false">
          <el-input type="textarea" v-model="contactForm.message" :rows="5"></el-input>
        </el-form-item>
      </el-col>
      <el-col :xs="24">
        <p class="small">* Required</p>
      </el-col>
      <el-col :xs="24">
        <el-alert v-if="success" class="mb-3 mt-3"
          description="Thanks for submitting your inquiry. One of our support team will get in touch with you."
          type="success" @close="alertClosed" effect="dark">
        </el-alert>
      
        <el-alert 
          v-if="error"
          type="error"
          title="The following is required:"
          :closable="click=false"
          @close="true"
          class="application-alert">
          <ul>
            <li
                v-for="first in error['contact.first_name']" :key="first">
              <i class="el-icon-error"></i> {{ first }}
            </li>
            <li v-for="last in error['contact.last_name']" :key="last">
              <i class="el-icon-error"></i> {{ last }}
            </li>
            <li v-for="email in error['contact.email']" :key="email">
              <i class="el-icon-error"></i> {{ email }}
            </li>
            <li v-for="subject in error['contact.subject']" :key="subject">
              <i class="el-icon-error"></i> {{ subject }}
            </li>
            <li v-for="message in error['contact.message']" :key="message">
              <i class="el-icon-error"></i> {{ message }}
            </li>
          </ul>
        </el-alert>
      </el-col>
      <el-col :xs="24">
        <div class="text-center buttons">
          <el-button type="warning" @click="contactUs(contactForm)">Submit
            <i class="el-icon-loading" v-show="status === 'loading'"></i>
          </el-button>
        </div>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
  import store from '../vuex/store';
  import {mapGetters, mapActions} from 'vuex'
  export default {
    store,
    data() {
      return {
       labelPosition: 'top',
       contactForm: {
        first_name: '',
        last_name: '',
        email: '',
        subject: '',
        message: '',
       },
      }
    },
    computed: {
      ...mapGetters({
        status: "authStatus",
        error: "getContactErr",
        success: "getContact",
      })
    },
    methods: {
       ...mapActions({
         contactUs: "contact",
       }),

      alertClosed() {
        Object.assign(this.$data, this.$options.data())
      }

       //   submit () {
    //     axios.post('https://api.hsforms.com/submissions/v3/integration/submit/:portalId/:formGuid', {
    //      "fields": [
    //         {
    //           "name": "firstname",
    //           "value": this.contactForm.firstName,
    //         },
    //         {
    //           "name": "lastname",
    //           "value": this.contactForm.lastName,
    //         },
    //         {
    //           "name": "email",
    //           "value": this.contactForm.email,
    //         },
    //         {
    //           "name": "subject",
    //           "value": this.contactForm.subject,
    //         },
    //         {
    //           "name": "message",
    //           "value": this.contactForm.message,
    //         },
    //       ],
    //       context: {
    //         pageUri: window.location.href,
    //         pageName: document.title,
    //       },
    //     }).then(response => {
    //       this.$message({
    //         message: response.data.inlineMessage ? response.data.inlineMessage : 'Thanks for submitting your inquiry. One of our support team will get in touch with you.',
    //         type: 'success',
    //       })
    //       // clear the fields
    //       this.contact = {
    //         firstName: '',
    //         lastName: '',
    //         email: '',
    //         subject: '',
    //         message: '',
    //       }
    //     })
    //   },
     }
  }
</script>

<style scoped>
  .buttons {
    margin-top: 15px;
  }
  .buttons button:focus, .buttons button:active {
    outline: 0;
  }
  .buttons>>>.el-button--warning:hover{
    background-color: #e86b0b !important;
  }
  .is-required>>>.el-form-item__label:after{
    content: "*";
    color: #f56c6c;
    margin-left: 4px;
  }
  .contact-form .is-required{
    margin-bottom: 10px;
  }
  .contact-form ul{
    margin-bottom: 5px;
    padding-left: 0;
  }
  .contact-form li{
    margin-right: 10px;
  margin-left: 0 !important;
}
</style>