import axios from 'axios'
import { LOCAL_BASE_URL } from '../vuex/utils/constant.js'
import UTM from './utmParser'
export const Cookies = {}

const getDomain = ()  => {
    if (document.domain.length) {
        var parts = document.domain.replace(/^(www\.)/, "").split('.');

        //is there a subdomain? 
        // while (parts.length > 2) {
        //     //removing it from our array 
        //     var subdomain = parts.shift();
        // }

        //getting the remaining 2 elements
        var domain = parts.join('.');

        return domain.replace(/(^\.*)|(\.*$)/g, "");
    }
    return '';
}

Cookies.create = (name, value, days) => {
    var expires = ""
    if (days) {
        var date = new Date()
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString()
    }
    document.cookie = name + "=" + value + expires + "; domain=" + getDomain() + "; path=/"
}

Cookies.read = (name) => {
    var nameEQ = name + "="
    var ca = document.cookie.split(';')
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i]
        while (c.charAt(0) == ' ') c = c.substring(1, c.length)
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
    }
    return null
}

Cookies.remove = (name) => {
    this.create(name, "", -1)
}

/**
* RefCookie
 */
// TODO: Set axios post to store the source, location and referrer and response with the token id
// If token Id is present then do not run axios request else re run axios post request
export const RefCookie = (to, from) => {
    console.log('from', from)
    const referrer = document.referrer
    const location = window.location.host + to.fullPath
    const ref = 
                Cookies.read('ref') && 
                Cookies.read('ref') !== undefined && 
                Cookies.read('ref') !== null && 
                JSON.parse(Cookies.read('ref'))

    if (ref === undefined || ref === null) {
        const utm = UTM(location).get || null
        axios.post(`${LOCAL_BASE_URL}/api/v1/referrer`, {
            ref_url: referrer,
            landing_url: location,
            utm
        })
            .then(r => {
                if (r.data !== undefined && r.data.ref_url_id !== undefined) {
                    Cookies.create('ref', JSON.stringify({ ref_url_id: r.data.ref_url_id }), 180)
                }
            })
        return
    }
    axios.post(`${LOCAL_BASE_URL}/api/v1/return-visit`, {
        ref
    })
}