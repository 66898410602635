import Model from '~/models/Model'
import axios from '../client';
import * as constant from '../vuex/utils/constant';

export default class Employer extends Model {
  static get resource () {
    return 'employer'
  }

  static getSubscriptionName () {
    return this.axios().get('subscription-name')
  }

  static async toggleBookmarkedFreelancer(freelancer) {
    let user = JSON.parse(localStorage.getItem('user'));
    if (freelancer.data.bookmark) {
      await axios
        .post(`${constant.LOCAL_BASE_URL}${constant.EMPLOYER}/bookmark-freelancers/${freelancer.data.bookmark.bookmark_id_freelancer}/delete`, {}, {
          headers: {
            Authorization: 'Bearer ' + user.authorization.access_token
          }
        });
      return null;
    } else {
      let d = {
				freelancer_id: freelancer.data.id
			};
      let response = await axios
        .post(`${constant.LOCAL_BASE_URL}${constant.EMPLOYER}/bookmark-freelancers`, d, {
          headers: {
            Authorization: 'Bearer ' + user.authorization.access_token
          }
        });
        return response.data;
    }
  }
}
