import axios from 'axios'
import { API_BASE_URL } from '~/vuex/utils/constant'
import getToken from '~/helpers/getToken'
import handleError from '~/helpers/handleError'
import Vue from 'vue'
import { Message, MessageBox } from 'element-ui'
import $ from 'jquery'


export default class Model {
  constructor (id, data = {}, fetch = true) {
    this.id = id

    const resourceWrapped = this.constructor.resourceWrapped

    // build axios instance for this model instance
    this.buildAxios()

    // declare so Vue's reactivity can detect it
    this.data = data

    if (id && fetch) {
      this.axios.get().then(response => {
        this.data = resourceWrapped ? response.data.data : response.data
      })
    }

    this.saving = false
    this.deleting = false
    this.errors = {}
  }

  static axios () {
    return axios.create({
      baseURL: this.baseURL(),
      headers: this.headers(),
    })
  }

  buildAxios () {
    this.axios = axios.create({
      baseURL: this.constructor.baseURL(this.id),
      headers: this.constructor.headers(),
    })
  }

  static baseURL (path = '') {
    return API_BASE_URL + '/' + this.resource + '/' + path
  }

  static headers (options = {}) {
    // set default headers, and merge provided headers
    const headers = {
      accept: 'application/json',
    }

    // get the token (whether onboarding or normal session)
    const token = getToken()

    // if there's a token, add it to the headers
    // on page 1 of SignUp, requests don't have tokens
    if (token) {
      headers.Authorization = 'Bearer ' + token
    }

    // return the headers, and merge provided headers
    return {
      ...headers,
      ...options,
    }
  }

  getError (field) {
    const error = this.errors[field]
    if (error) {
      if (Array.isArray(error)) {
        return error.length > 0 ? error[0] : null
      }
      return error
    }
    return null
  }

  clearError (field) {
    Vue.delete(this.errors, field)
  }

  confirmDelete (options = {}) {
    // extract options
    const {
      redirect,
      successMessage,
      confirmationMessage,
      modifier,
      loadingStatusProperty,
    } = {
      // set defaults of options
      ...{
        redirect: false,
        successMessage: 'Successfully deleted!',
        confirmationMessage: `Are you sure you want to ${options.modifier ? options.modifier : 'delete'} this?`,
        modifier: '',
        loadingStatusProperty: 'deleting',
      },
      // override defaults if provided
      ...options,
    }

    // return a promise so we can .then() and .catch() this method
    return new Promise((resolve, reject) => {
      // confirm with the user
      MessageBox.confirm(confirmationMessage).then(() => {
        // set loading state of delete button
        this[loadingStatusProperty] = true
        // if user confirms, send the delete request
        this.axios.delete(modifier).then(response => {
          // reset loading state
          this[loadingStatusProperty] = false
          // if success message is set, show the message
          if (successMessage) Message.success({ message: successMessage, offset: 150 })
          // if redirect is set, redirect
          if (redirect) window.location.replace(redirect)
          // resolve so we can use .then() on this method
          resolve(response)
        }).catch(error => {
          // reset loading state
          this[loadingStatusProperty] = false
          // notify user of error
          handleError(error)
          // reject so we can use .catch() on this method
          reject(error)
        })
      }).catch(reject)
    })
  }

  save (options = {}) {
    // extract options
    const {
      redirect,
      successMessage,
    } = {
      // set defaults of options
      ...{
        redirect: false,
        successMessage: 'Successfully saved!',
      },
      // override defaults if provided
      ...options,
    }
    this.saving = true
    const axios = this.axios
    const id = this.id
    let promise
    if (id) {
      promise = axios.put('', this.data)
    } else {
      promise = axios.post('', this.data)
    }
    return new Promise((resolve, reject) => {
      promise.then(response => {
        this.saving = false
        if (successMessage) Message.success({ message: successMessage, offset: 100 })
        if (redirect) window.location.href = redirect
        this.errors = {}
        resolve(response)
      }).catch(error => {
        this.saving = false
        const response = error.response
        if (response && response.status === 422) {
          this.errors = response.data.errors
        } else {
          handleError(error)
        }
        reject(error)
      })
    })
  }

  static all (page = 1, perPage = 10, query = {}, scope = '') {
    return this.axios().get(scope + '?' + $.param({
      ...query,
      ...{ page, perPage },
    }))
  }

  static find (id = '') {
    return this.axios().get(id.toString())
  }

  static search (options) {
    return this.axios().post('search', options)
  }

  static create (data) {
    return this.axios().post('', data)
  }

  static update (id, data) {
    return this.axios().put(id.toString(), data)
  }

  static destroy (id, config = []) {
    return this.axios().delete(id.toString(), config)
  }
}
