import getUser from '~/helpers/getUser'
import getUserType from '~/helpers/getUserType'

export default () => {
  const USER = getUser()

  if (! USER) {
    return
  }
  return USER[getUserType() + '_id']
}
