import Home from '../views/Home.vue';

// Terms
// import PrivacyPolicy from '../views/Terms/PrivacyPolicy.vue'
// import TermsOfService from '../views/Terms/TermsOfService.vue'

// Beta
// import BetaEmployerSignup from '../views/Beta/EmployerSignup.vue'
import BetaSignup from '../views/Beta/Signup.vue'

// import AboutUs from '../views/AboutUs.vue'
// import FreelancerElevator from '../views/FreelancerElevator.vue'
// import EmployerElevator from '../views/EmployerElevator.vue'
// import EmployerMarketplace from '../views/EmployerMarketplace.vue'
// import CitronWorkers from '../views/Citronworkers.vue'
// Concierge page:
// import EmployerHome from '../views/Employer/Home.vue'

// Auth
// import Login from '../views/Login.vue'
// import ForgotPassword from '../views/ForgotPassword.vue'
// import ResetPassword from '../views/ResetPassword.vue'

// Transactions
// import FreelancerTransactionList from '../views/Contractor/TransactionList.vue'

// Employer Transaction
// import EmployerTransactionList from '../views/Employer/TransactionList.vue'

// FreelancerCompany Transactions
// import FreelancerCompanyTransactionList from '../views/FreelancerCompany/TransactionList.vue'

// Add-ons
// import AddOns from '../views/AddOns/AddOns.vue'
// import AddOnView from '../views/AddOns/AddonView.vue'
// import BuyAddon from '../views/AddOns/BuyAddon.vue'

// Freelancers
// import Feed from '../views/Contractor/Feed.vue'
// import FreelancerJobsList from '../views/Freelancer/Jobs/List.vue'
// import FreelancerAppliedJobs from '../views/Freelancer/Jobs/Applied.vue'
// import FreelancerBookmarkedJobs from '../views/Freelancer/Jobs/Bookmarked.vue'
// import JobView from '../views/Contractor/JobView.vue'
// import JobApplication from '../views/Contractor/JobApplication.vue'
// import JobScreening from '../views/Contractor/JobScreening.vue'
// import JobScreeningIncorrect from '../views/Contractor/JobScreeningIncorrect.vue'
// import SignUp from '../views/Contractor/Signup.vue'
// import AccountDetails from '../views/Freelancer/Account/Details.vue'
// import MyProfile from '../views/Freelancer/Account/MyProfile.vue'

// Freelancer Company
// import ContractingSignUp from '../views/FreelancerCompany/Signup.vue'
// import CompanyFeed from '../views/FreelancerCompany/Feed.vue'
// import CompanyAccountDetails from '../views/FreelancerCompany/AccountDetails.vue'
// import Billing from '../views/FreelancerCompany/Billing'
// import FreelancerCompanyInformation from '../views/FreelancerCompany/CompanyInformation.vue'

// Employer
// import EmployerSignUp from '../views/Employer/Signup.vue'
// import EmployerFeed from '../views/Employer/Feed.vue'
// import EmployerAccountDetails from '../views/Employer/AccountDetails.vue'
// import EmployerCompanyInformation from '../views/Employer/CompanyInformation.vue'
// import EmployerJobsList from '../views/Employer/Jobs/List.vue'
// import EmployerJobView from '../views/Employer/Jobs/View.vue'
// import EmployerJobForm from '../views/Employer/Jobs/Form.vue'
// import EmployerFreelancerList from '../views/Employer/Freelancers/List.vue'
// import EmployerStarredFreelancers from '../views/Employer/StarredFreelancers.vue'
// import EmployerHiredFreelancers from '../views/Employer/HiredFreelancers.vue'
// import ProfileView from '../views/Employer/ProfileView.vue'
// import ApplicationView from '../views/Employer/ApplicationView.vue'
// import Conversation from '../views/Messenger/Conversation/View.vue'
// import ConversationList from '../views/Messenger/Conversation/List.vue'

// Error pages
// import Page404 from '../views/404.vue'
// import Page500 from '../views/500.vue'

// Misc
// import Notifications from '../views/Notifications.vue'
// import ContactForm from '../views/Contact.vue'
// import Unsubscribe from '../views/Unsubscribe.vue'

// Test
// import SkillSearch from '../views/Freelancer/SkillSearch'
// import SkillList from '../views/Test/SkillList.vue'

// import AccountDetails from '../views/Contractor/AccountDetails.vue'
// import AccountDetails from '../views/Freelancer/AccountDetails.vue'

import { guest, authorize, employer, freelancer } from '../vuex/utils/authentication';
// import EmployerBilling from '../views/Employer/EmployerBilling'

// Pricing
// import EmployerPricing from '../views/Employer/Pricing.vue'
// import FreelancerCompanyPricing from '../views/FreelancerCompany/Pricing.vue'

const defaultRoutes = [
  // Terms
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import(/* webpackChunkName: "privacy-policy" */ '~/views/Terms/PrivacyPolicy.vue')
    // beforeEnter: guest,
  },
  {
    path: '/terms-of-service',
    name: 'TermsOfService',
    component: () => import(/* webpackChunkName: "terms-of-service" */ '~/views/Terms/TermsOfService.vue')
    // component: TermsOfService,
    // beforeEnter: guest,
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: () => import(/* webpackChunkName: "about-us" */ '~/views/AboutUs.vue'),
    // component: AboutUs,
    beforeEnter: guest
  },
  {
    path: '/freelancers',
    name: 'FreelancerElevator',
    component: () => import(/* webpackChunkName: "freelancers-page" */ '~/views/FreelancerElevator.vue'),
    // component: FreelancerElevator,
    beforeEnter: guest
  },
  {
    path: '/marketplace',
    name: 'EmployerMarketplace',
    component: () => import(/* webpackChunkName: "marketplace" */ '~/views/EmployerMarketplace.vue'),
    // component: EmployerMarketplace,
    beforeEnter: guest
  },
  {
    path: '/employers',
    name: 'EmployerElevator',
    component: () => import(/* webpackChunkName: "employers-page" */ '~/views/EmployerElevator.vue'),
    // component: EmployerElevator,
    beforeEnter: guest
  },
  {
    path: '/citronworkers',
    name: 'CitronWorkers',
    component: () => import(/* webpackChunkName: "citronworkers" */ '~/views/Citronworkers.vue'),
    // component: CitronWorkers,
    beforeEnter: guest
  },
  {
    path: '/concierge',
    name: 'EmployerHome',
    component: () => import(/* webpackChunkName: "concierge" */ '~/views/Employer/Home.vue'),
    // component: EmployerHome,
    beforeEnter: guest
  },
  {
    path: '/employer/account',
    name: 'EmployerAccount',
    component: () => import(/* webpackChunkName: "account-details" */ '~/views/Employer/AccountDetails.vue'),
    // component: AccountDetails,
    beforeEnter: employer
    // beforeEnter: authorize,
  },
  {
    path: '/employer/companyinformation',
    name: 'EmployerCompanyInformation',
    component: () => import(/* webpackChunkName: "company-information" */ '~/views/Employer/CompanyInformation.vue'),
    // component: EmployerCompanyInformation,
    beforeEnter: employer
  },
  // {
  //   path: '/freelancercompany/companyinformation',
  //   name: 'FreelancerCompanyInformation',
  //   component: FreelancerCompanyInformation,
  //   beforeEnter: freelancer,
  // },
  {
    path: '/accountdetails',
    name: 'AccountDetails',
    component: () => import(/* webpackChunkName: "account-details" */ '~/views/Freelancer/Account/Details.vue'),
    // component: AccountDetails,
    beforeEnter: freelancer
  },
  {
    path: '/my-profile',
    name: 'MyProfile',
    component: () => import(/* webpackChunkName: "my-profile" */ '~/views/Freelancer/Account/MyProfile.vue'),
    // component: MyProfile,
    beforeEnter: freelancer
  },
  // {
  //   path: '/freelancer_company/betasignup',
  //   name: 'FreelancerCompanyBetaSignup',
  //   component: BetaSignup,
  // },
  {
    path: '/email-signup',
    name: 'BetaSignup',
    component: BetaSignup,
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: guest
  },
  {
    path: '/freelancersignup',
    name: 'Signup',
    // redirect: '/upgrading',
    component: () => import(/* webpackChunkName: "freelancer-signup" */ '~/views/Contractor/Signup.vue'),
    // component: SignUp,
    beforeEnter: guest
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '~/views/Login.vue'),
    // component: Login,
    beforeEnter: guest
  },
  {
    path: '/email-verification',
    name: 'EmailVerification',
    component: () => import(/* webpackChunkName: "email-verification" */ '~/views/EmailVerification.vue'),
  },
  {
    path: '/forgotpassword',
    name: 'ForgotPassword',
    component: () => import(/* webpackChunkName: "forgot-password" */ '~/views/ForgotPassword.vue')
    // component: ForgotPassword,
    // beforeEnter: guest,
  },
  {
    path: '/resetpassword',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "reset-password" */ '~/views/ResetPassword.vue')
    // component: ResetPassword,
    // beforeEnter: guest,
  },
  {
    path: '/feed',
    name: 'Feed',
    component: () => import(/* webpackChunkName: "freelancer-feed" */ '~/views/Contractor/Feed.vue'),
    // component: Feed,
    beforeEnter: freelancer
  },
  // {
  //   path: '/jobs/:tab?',
  //   name: 'Jobs',
  //   component: Jobs,
  //   beforeEnter: freelancer,
  //   // beforeEnter: authorize,
  // },
  {
    path: '/freelancer/jobs',
    name: 'FreelancerJobsList',
    component: () => import(/* webpackChunkName: "freelancer-job-list" */ '~/views/Freelancer/Jobs/List.vue'),
    // component: FreelancerJobsList,
    beforeEnter: freelancer
  },
  {
    path: '/employer/jobs',
    name: 'EmployerJobs',
    // component: EmployerJobsList,
    component: () => import(/* webpackChunkName: "employer-job-list" */ '~/views/Employer/Jobs/List.vue'),
    beforeEnter: employer
  },
  // {
  //   path: '/addons',
  //   name: 'AddOns',
  //   component: AddOns,
  //   beforeEnter: authorize,
  // },
  // {
  //   path: '/employer/addons',
  //   name: 'EmployerAddOns',
  //   component: AddOns,
  //   beforeEnter: employer,
  // },
  // {
  //   path: '/addonview',
  //   name: 'AddOnView',
  //   component: AddOnView,
  //   beforeEnter: authorize,
  // },
  // {
  //   path: '/buyaddon',
  //   name: 'BuyAddon',
  //   component: BuyAddon,
  //   beforeEnter: authorize,
  // },
  {
    path: '/freelancer/jobs/applied',
    name: 'FreelancerAppliedJobs',
    component: () => import(/* webpackChunkName: "freelancer-applied-jobs" */ '~/views/Freelancer/Jobs/Applied.vue'),
    // component: FreelancerAppliedJobs,
    beforeEnter: freelancer
  },
  {
    path: '/freelancer/jobs/bookmarked',
    name: 'FreelancerBookmarkedJobs',
    component: () => import(/* webpackChunkName: "freelancer-bookmarked-jobs" */ '~/views/Freelancer/Jobs/Bookmarked.vue'),
    // component: FreelancerBookmarkedJobs,
    beforeEnter: freelancer
  },
  {
    path: '/freelancer/jobs/:job_id',
    name: 'JobView',
    component: () => import(/* webpackChunkName: "freelancer-jobview" */ '~/views/Contractor/JobView.vue'),
    // component: JobView,
    beforeEnter: freelancer
  },
  {
    path: '/freelancer/job/deactivated',
    name: 'JobDeactivated',
    component: () => import(/* webpackChunkName: "freelancer-jobdeactivated" */ '~/views/Freelancer/Jobs/Deactivated.vue'),
    beforeEnter: freelancer
  },
  {
    path: '/jobs/apply/:job_id',
    name: 'JobApplication',
    component: () => import(/* webpackChunkName: "job-application" */ '~/views/Contractor/JobApplication.vue'),
    // component: JobApplication,
    beforeEnter: freelancer
  },
  {
    path: '/jobs/screening-question',
    name: 'JobScreening',
    component: () => import(/* webpackChunkName: "job-screening" */ '~/views/Contractor/JobScreening.vue'),
    // component: JobScreening,
    beforeEnter: freelancer
  },
  {
    path: '/jobs/screening-question/incorrect',
    name: 'JobScreeningIncorrect',
    component: () => import(/* webpackChunkName: "job-screening-incorrect" */ '~/views/Contractor/JobScreeningIncorrect.vue'),
    // component: JobScreeningIncorrect,
    beforeEnter: freelancer
  },
  {
    path: '/employer/jobs/create',
    name: 'EmployerJobCreate',
    component: () => import(/* webpackChunkName: "job-create" */ '~/views/Employer/Jobs/Form.vue'),
    // component: EmployerJobForm,
    beforeEnter: employer
  },
  {
    path: '/employer/jobs/:job_id',
    name: 'EmployerJobView',
    component: () => import(/* webpackChunkName: "job-view" */ '~/views/Employer/Jobs/View.vue'),
    // component: EmployerJobView,
    beforeEnter: employer
  },
  {
    path: '/employer/jobs/:job_id/applications',
    name: 'EmployerJobApplications',
    component: () => import(/* webpackChunkName: "job-applications" */ '~/views/Employer/Jobs/Applications.vue'),
    beforeEnter: employer
  },
  {
    path: '/employer/jobs/:job_id/edit',
    name: 'EmployerJobEdit',
    component: () => import(/* webpackChunkName: "job-edit" */ '~/views/Employer/Jobs/Form.vue'),
    // component: EmployerJobForm,
    beforeEnter: employer
  },
  {
    path: '/employer/profile/:freelancer_id',
    name: 'ProfileView',
    component: () => import(/* webpackChunkName: "profile-view" */ '~/views/Employer/ProfileView.vue'),
    // component: ProfileView,
    beforeEnter: employer
  },
  {
    path: '/employer/application/:application_id',
    name: 'ApplicationView',
    component: () => import(/* webpackChunkName: "application-view" */ '~/views/Employer/ApplicationView.vue'),
    // component: ApplicationView,
    beforeEnter: employer
  },
  {
    path: '/conversations',
    name: 'ConversationList',
    component: () => import(/* webpackChunkName: "conversation-list" */ '~/views/Messenger/Conversation/List.vue'),
    // component: ConversationList,
    beforeEnter: authorize
  },
  {
    path: '/conversations/:conversation_id',
    name: 'Conversation',
    component: () => import(/* webpackChunkName: "conversation-view" */ '~/views/Messenger/Conversation/View.vue'),
    // component: Conversation,
    beforeEnter: authorize
  },
  {
    path: '/employer/freelancers',
    name: 'EmployerFreelancerList',
    component: () => import(/* webpackChunkName: "freelancer-list" */ '~/views/Employer/Freelancers/List.vue'),
    // component: EmployerFreelancerList,
    beforeEnter: employer
  },
  {
    path: '/employer/bookmarked-freelancers',
    name: 'EmployerBookmarkedFreelancers',
    component: () => import(/* webpackChunkName: "starred-list" */ '~/views/Employer/BookmarkedFreelancers.vue')
    // beforeEnter: ifAuthenticated,
  },
  {
    path: '/employer/hiredfreelancers',
    name: 'EmployerHiredFreelancers',
    component: () => import(/* webpackChunkName: "hired-list" */ '~/views/Employer/HiredFreelancers.vue')
    // component: EmployerHiredFreelancers,
    // beforeEnter: ifAuthenticated,
  },
  {
    path: '/transactions',
    name: 'FreelancerTransactionList',
    component: () => import(/* webpackChunkName: "transaction-list" */ '~/views/Contractor/TransactionList.vue'),
    // component: FreelancerTransactionList,
    beforeEnter: authorize
  },
  // {
  //   path: '/freelancercompany/signup',
  //   name: 'ContractingSignup',
  //   component: ContractingSignUp,
  //   beforeEnter: guest,
  // },
  // {
  //   path: '/freelancercompany/accountdetails',
  //   name: 'CompanyAccountDetails',
  //   component: CompanyAccountDetails,
  //   beforeEnter: authorize,
  // },
  // {
  //   path: '/freelancercompany/feed',
  //   name: 'CompanyFeed',
  //   component: CompanyFeed,
  // },
  // {
  //   path: '/freelancercompany/transactions',
  //   name: 'TransactionList',
  //   component: FreelancerCompanyTransactionList,
  //   beforeEnter: authorize,
  // },
  // {
  //   path: '/freelancercompany/billing',
  //   name: 'Billing',
  //   component: Billing,
  // },
  {
    path: '/employer/signup',
    name: 'EmployerSignup',
    // redirect: '/upgrading',
    component: () => import(/* webpackChunkName: "employer-signup" */ '~/views/Employer/Signup.vue'),
    // component: EmployerSignUp,
    beforeEnter: guest
  },
  {
    path: '/employer/feed',
    name: 'EmployerFeed',
    component: () => import(/* webpackChunkName: "employer-feed" */ '~/views/Employer/Feed.vue'),
    // component: EmployerFeed,
    beforeEnter: employer
  },
  {
    path: '/employer/accountdetails',
    name: 'EmployerAccountDetails',
    component: () => import(/* webpackChunkName: "employer-account-details" */ '~/views/Employer/AccountDetails.vue'),
    // component: EmployerAccountDetails,
    beforeEnter: employer
  },
  {
    path: '/employer/transactions',
    name: 'EmployerTransactionList',
    component: () => import(/* webpackChunkName: "transaction-list" */ '~/views/Employer/TransactionList.vue'),
    beforeEnter: employer
    // beforeEnter: ifAuthenticated,
  },
  {
    path: '/employer/billing',
    name: 'EmployerBilling',
    component: () => import(/* webpackChunkName: "billing" */ '~/views/Employer/EmployerBilling'),
    // component: EmployerBilling,
    beforeEnter: employer
  },
  {
    path: '/page500',
    name: 'Page500',
    component: () => import(/* webpackChunkName: "500" */ '~/views/500.vue'),
    // component: Page500,
    beforeEnter: guest
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: () => import(/* webpackChunkName: "notifications" */ '~/views/Notifications.vue'),
    // component: Notifications,
    beforeEnter: authorize
  },
  {
    path: '/my-skills/skill-search',
    name: 'SkillSearch',
    component: () => import(/* webpackChunkName: "skill-search" */ '~/views/Freelancer/SkillSearch')
    // component: SkillSearch,
  },
  {
    path: '/my-skills/skill-list',
    name: 'SkillList',
    component: () => import(/* webpackChunkName: "skill-search" */ '~/views/Test/SkillList.vue')
    // component: SkillList,
  },
  {
    path: '/plans',
    name: 'EmployerPricing',
    component: () => import(/* webpackChunkName: "plans" */ '~/views/Employer/Pricing.vue'),
    // component: EmployerPricing,
    beforeEnter: guest
  },
  // {
  //   path: '/freelancercompany/pricing',
  //   name: 'FreelancerCompanyPricing',
  //   component: FreelancerCompanyPricing
  // },
  {
    path: '/contact-form',
    name: 'ContactForm',
    component: () => import(/* webpackChunkName: "contact-form" */ '~/views/Contact.vue')
    // component: ContactForm,
  },
  {
    path: '/unsubscribe',
    name: 'Unsubscribe',
    component: () => import(/* webpackChunkName: "unsubscribe" */ '~/views/Unsubscribe.vue')
  },
  {
    path: '/upgrading',
    name: 'Construction',
    component: () => import(/* webpackChunkName: "unsubscribe" */ '~/views/Construction.vue')
  },
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '~/views/404.vue'),
    // component: Page404,
    beforeEnter: guest
  }
];

// const routes = defaultRoutes.concat(userRoutes[provider()]);
//
// const router = new VueRouter({
//   mode: 'history',
//   saveScrollPosition: true,
//   routes
// });

export default defaultRoutes;
