//actions.js
import axios from 'axios';
import { Message } from 'element-ui';
import * as constant from './utils/constant';
import { FREELANCER, FREELANCER_REQUEST, FREELANCER_API_ERROR, FREELANCER_API_SUCCESS, FREELANCER_BIO_API_SUCCESS, FREELANCER_BIO_API_ERROR, EMPLOYER, EMPLOYER_REQUEST, EMPLOYER_API_SUCCESS, EMPLOYER_API_ERROR, FREELANCER_COMPANY, FREELANCER_COMPANY_API_SUCCESS, FREELANCER_COMPANY_API_ERROR, DISABLE, API_ERROR_RESPONSE, API_SUCCESSFUL_RESPONSE, EMPLOYER_API_ON_SUCCESS, EMPLOYER_ON_REQUEST, EMPLOYER_PROFILE_ON_REQUEST, EMPLOYER_PROFILE_ON_UPDATE, EMPLOYER_ADD_TRANSACTION, ENABLE, AGREEMENT, AUTH_REQUEST, AUTH_ERROR, AUTH_SUCCESS, JOBS_ERROR, JOBS_SHOW, JOB_APPLICATION_SUCCESS, JOB_APPLICATION_ON_LOADING, JOB_APPLICATION_ERROR, FORGOT_SUCCESS, FORGOT_ERROR, RESET_SUCCESS, RESET_ERROR, PRESENTATION_ERROR, PRESENTATION_REQUEST, PRESENTATION_SUCCESS, COUNTRY, CONTACT_REQUEST, CONTACT_SUCCESS, CONTACT_ERROR, CONCIERGE_REQUEST, CONCIERGE_SUCCESS, CONCIERGE_ERROR, FREELANCER_ADDRESS_API_SUCCESS, FREELANCER_ADDRESS_API_ERROR, EMPLOYER_CMP_PROFILE_API_ERROR, EMPLOYER_UPDATE_ACCT_DETAILS, CITRONWORKERS_REQUEST, CITRONWORKERS_SUCCESS, CITRONWORKERS_ERROR, JOB_APPLICATION_ATTACHMENT, FREE_SUBSCRIPTIONS_COUNT, FREELANCER_PROFILE_PROGRESS, EMPLOYER_BOOKMARKED_FREELANCERS, EMAIL_VERIFY_ERR, EMAIL_VERIFY_RESP, INVITE_INFORMATION_RESP,INVITE_INFORMATION_ERR } from './mutation_types';
import {LOCAL_BASE_URL, OAUTH, OAUTH_VERIFY_EMAIL} from './utils/constant';

export default {
  /**
   * Presentation
   */
  invite: ({ commit }, data) => {
    commit(CONCIERGE_REQUEST);
    commit(PRESENTATION_REQUEST);
    let request = {
      first_name: '',
      last_name: '',
      email: '',
      company_name: '',
      company_website: '',
      phone: '',
      message: '',
      referrer: '',
      member_category: 'employer',
      agreement: false,
      concierge_subscribe: false,
      successMessage: 'Thanks for signing up ' + data.first_name + "! We'll get back to you when we start onboarding employers."
    };
    axios
      .post(constant.LOCAL_BASE_URL + '/api/v1/presentation/invite', data)
      .then(response => {
        commit(PRESENTATION_SUCCESS, request);
        commit(CONCIERGE_SUCCESS, request);
        return response;
      })
      .catch(error => {
        commit(PRESENTATION_ERROR, error.response.data.errors);
        commit(CONCIERGE_ERROR, error.response.data.errors);
        return error;
      });
  },
  /**
   * Contact
   * @param commit
   * @param data
   */
  contact: ({ commit }, data) => {
    commit(CONTACT_REQUEST);
    axios
      .post(constant.LOCAL_BASE_URL + '/api/v1/contact', { contact: data })
      .then(response => {
        commit(CONTACT_SUCCESS, response.data);
      })
      .catch(error => {
        commit(CONTACT_ERROR, error.response.data.errors);
      });
  },

  /**
   * Contact
   * @param commit
   * @param data
   */
  conciergeRequest: ({ commit }, data) => {
    commit(CONCIERGE_REQUEST);
    commit(PRESENTATION_REQUEST);
    let request = {
      first_name: '',
      last_name: '',
      email: '',
      company_name: '',
      company_website: '',
      phone: '',
      message: '',
      referrer: '',
      member_category: 'employer',
      agreement: false,
      concierge_subscribe: false,
      successMessage: 'Thanks for signing up ' + data.first_name + '! One of our representatives will get back to you as soon as possible.'
    };
    axios
      .post(constant.LOCAL_BASE_URL + '/api/v1/employer/concierge/request', { concierge_request: data })
      .then(response => {
        commit(PRESENTATION_SUCCESS, request);
        commit(CONCIERGE_SUCCESS, request);
        return response;
      })
      .catch(error => {
        commit(PRESENTATION_ERROR, error.response.data.errors);
        commit(CONCIERGE_ERROR, error.response.data.errors);
        return error;
      });
  },
  citronworkersRequest: ({ commit }, data) => {
    commit(CITRONWORKERS_REQUEST);
    axios
      .post(constant.LOCAL_BASE_URL + '/api/v1/employer/citronworkers/request', { citronworkers_request: data })
      .then(response => {
        commit(CITRONWORKERS_SUCCESS, data);
        return response;
      })
      .catch(error => {
        commit(CITRONWORKERS_ERROR, error.response.data.errors);
        return error;
      });
  },

  /**
   *
   * @param commit
   * @param data
   */
  authentication: ({ commit }, data) => {
    commit(AUTH_REQUEST);
    let formData = {
      username: data.username,
      password: data.password
    };

    if (data._fromSignup) {
      formData.from_signup = true;
    }

    axios
      .post(constant.LOCAL_BASE_URL + constant.OAUTH, formData)
      .then(response => {
        const user = response.data;
        localStorage.setItem('user', JSON.stringify(user));
        commit(AUTH_SUCCESS, user);

        switch (user.provider) {
          case 'freelancer':
            window.location.href = '/feed';
            // localStorage.removeItem('user');
            // window.location.href = '/upgrading';

            break;
          case 'company':
            window.location.href = '/freelancercompany/accountdetails';
            // localStorage.removeItem('user');
            // window.location.href = '/upgrading';
            break;
          case 'employer':
            window.location.href = '/employer/feed';

            // localStorage.removeItem('user');
            // window.location.href = '/upgrading';
            break;
          default:
            window.location.href = '/login';
        }
      })
      .catch(error => {
        commit(AUTH_ERROR, error.response.data.errors);
        localStorage.removeItem('user');
      });
  },

  changePassword: ({ commit }, data) => {
    commit(EMPLOYER_ON_REQUEST, 'loading');
    return axios
      .post(constant.LOCAL_BASE_URL + constant.OAUTH_CHANGE_PASSWORD + '/' + data.provider + '/' + data.id, data.info)
      .then(response => {
        commit(EMPLOYER_API_ON_SUCCESS, true);
        commit(EMPLOYER_ON_REQUEST, '');
        commit(API_SUCCESSFUL_RESPONSE, response.data);
        setTimeout(() => {
          commit(EMPLOYER_API_ON_SUCCESS, false);
        }, 4000);
        return response;
      })
      .catch(error => {
        commit(API_ERROR_RESPONSE, error.response.data.errors);
        commit(EMPLOYER_API_ERROR, error.response.data.errors);
        setTimeout(() => {
          commit(EMPLOYER_API_ERROR, '');
        }, 4000);
        return error.response.data;
      });
  },

  logoutABC: () => {
    localStorage.removeItem('user');
    delete axios.defaults.headers.common['Authorization'];
    window.location.href = '/login';
  },

  // /**
  //  * Freelancer API
  //  * @param commit
  //  * @param data
  //  */
  // freelancerCreate: ({commit}, data) => {

  //     axios.post(constant.LOCAL_BASE_URL + constant.FREELANCER_SIGNUP, data)
  //         .then(response => {

  //             commit(API_SUCCESSFUL_RESPONSE, response);
  //             commit(DISABLE, true);
  //             commit(ENABLE, true);
  //             commit(AGREEMENT, false);
  //         })
  //         .catch(error => {
  //             commit(API_ERROR_RESPONSE, error.response.data.errors)
  //         });
  // },
  // freelancerCreateNext: ({commit}, data) => {
  //     axios.post(constant.LOCAL_BASE_URL + constant.FREELANCER_SIGNUP_NEXT, data)
  //         .then(response => {
  //             commit(API_SUCCESSFUL_RESPONSE, response);
  //         })
  //         .catch(error => {
  //             commit(API_ERROR_RESPONSE, error.response.data.errors)
  //         });
  // },

  freelancerGet: ({ commit }) => {
    let user = JSON.parse(localStorage.getItem('user'));
    axios
      .get(constant.LOCAL_BASE_URL + constant.FREELANCER + 's/my-profile', {
        headers: {
          Authorization: 'Bearer ' + user.authorization.access_token
        }
      })
      .then(response => {
        commit(FREELANCER, response.data.data);
      })
      .catch(error => {
        commit(FREELANCER_API_ERROR, error.response.data.errors);
      });
  },
  freelancerPut: ({ commit }, data) => {
    let user = JSON.parse(localStorage.getItem('user'));
    commit(FREELANCER_REQUEST);
    axios
      .post(
        constant.LOCAL_BASE_URL + constant.FREELANCER_UPDATE_NAME + '/' + data.freelancer_id,
        {
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email
        },
        {
          headers: {
            Authorization: 'Bearer ' + user.authorization.access_token
          }
        }
      )
      .then(response => {
        commit('detailSuccess', true);
        commit(FREELANCER_API_SUCCESS, response.data);
      })
      .catch(error => {
        commit('detailSuccess', false);
        commit(FREELANCER_API_ERROR, error.response.data.errors);
      });
  },
  freelancerPutBio: ({ commit }, data) => {
    let user = JSON.parse(localStorage.getItem('user'));
    commit(FREELANCER_REQUEST);
    axios
      .post(constant.LOCAL_BASE_URL + constant.FREELANCER_UPDATE_BIO + '/' + data.freelancer_id, data, {
        headers: {
          Authorization: 'Bearer ' + user.authorization.access_token
        }
      })
      .then(response => {
        commit('detailSuccess', true);
        commit(FREELANCER_BIO_API_SUCCESS, response.data);
      })
      .catch(error => {
        commit('detailSuccess', false);
        commit(FREELANCER_BIO_API_ERROR, error.response.data.errors);
      });
  },

  freelancerPutAddress: ({ commit }, data) => {
    let user = JSON.parse(localStorage.getItem('user'));
    commit(FREELANCER_REQUEST);
    axios
      .post(constant.LOCAL_BASE_URL + constant.FREELANCER_UPDATE_ADDRESS + '/' + data.freelancer_id, data, {
        headers: {
          Authorization: 'Bearer ' + user.authorization.access_token
        }
      })
      .then(response => {
        commit('detailSuccess', true);
        commit(FREELANCER_ADDRESS_API_SUCCESS, response.data);
      })
      .catch(error => {
        commit('detailSuccess', false);
        commit(FREELANCER_ADDRESS_API_ERROR, error.response.data.errors);
      });
  },

  /**
   * Employer API
   * @param commit
   * @param data
   */
  // employerCreate: ({commit}, data) => {

  //     axios.post(constant.LOCAL_BASE_URL + constant.EMPLOYER_SIGNUP, data)
  //         .then(response => {
  //             commit(API_SUCCESSFUL_RESPONSE, response);
  //             commit(DISABLE, true);
  //             commit(ENABLE, true);
  //             commit(AGREEMENT, false);
  //         })
  //         .catch(error => {
  //             commit(API_ERROR_RESPONSE, error.response.data.errors)
  //         });
  // },
  employerCreateNext: ({ commit }, data) => {
    commit(EMPLOYER, data);
    axios
      .post(constant.LOCAL_BASE_URL, data)
      .then(response => {
        commit(API_SUCCESSFUL_RESPONSE, response);
      })
      .catch(error => {
        commit(API_ERROR_RESPONSE, error.response.data.errors);
      });
  },
  employerPut: ({ commit }, data) => {
    commit(EMPLOYER_REQUEST);
    let user = JSON.parse(localStorage.getItem('user'));
    axios
      .post(constant.LOCAL_BASE_URL + constant.EMPLOYER + '/' + data.employer_id, data, {
        headers: {
          Authorization: 'Bearer ' + user.authorization.access_token
        }
      })
      .then(response => {
        commit('employerDetailSuccess', true);
        commit(EMPLOYER_API_SUCCESS, response.data);
      })
      .catch(error => {
        commit(EMPLOYER_API_ERROR, error.response.data.errors);
      });
  },
  employerProfileUpdate({ commit }, model) {
    commit(EMPLOYER_PROFILE_ON_REQUEST, true);
    let user = JSON.parse(localStorage.getItem('user'));
    commit(EMPLOYER_ON_REQUEST, 'loading');
    axios
      .post(`${constant.LOCAL_BASE_URL}${constant.EMPLOYER}/update/profile`, model, {
        headers: {
          Authorization: 'Bearer ' + user.authorization.access_token
        }
      })
      .then(d => {
        commit(EMPLOYER_PROFILE_ON_REQUEST, false);
        commit(EMPLOYER_PROFILE_ON_UPDATE, true);
        commit(EMPLOYER_UPDATE_ACCT_DETAILS, d.data);
        setTimeout(() => {
          commit(EMPLOYER_PROFILE_ON_UPDATE, false);
        }, 4000);
        return d;
      });
  },
  employerGet: ({ commit }) => {
    let user = JSON.parse(localStorage.getItem('user'));
    return axios
      .get(constant.LOCAL_BASE_URL + constant.EMPLOYER + '/my-profile', {
        headers: {
          Authorization: 'Bearer ' + user.authorization.access_token
        }
      })
      .then(response => {
        commit(EMPLOYER, response.data);
      })
      .catch(error => {
        commit(EMPLOYER_API_ERROR, error.response.data.errors);
      });
  },

  employerUpdateCompanyProfile({ commit }, model) {
    commit(EMPLOYER_PROFILE_ON_REQUEST, true);
    commit(EMPLOYER_CMP_PROFILE_API_ERROR, '');
    let user = JSON.parse(localStorage.getItem('user'));
    return axios
      .post(constant.LOCAL_BASE_URL + constant.EMPLOYER + '/update/company-profile', model, {
        headers: {
          Authorization: 'Bearer ' + user.authorization.access_token
        }
      })
      .then(response => {
        commit(EMPLOYER_PROFILE_ON_REQUEST, false);
        commit(EMPLOYER_API_ON_SUCCESS, response.data);
        setTimeout(() => {
          commit(EMPLOYER_API_ON_SUCCESS, false);
        }, 4000);
      })
      .catch(error => {
        commit(EMPLOYER_PROFILE_ON_REQUEST, false);
        commit(EMPLOYER_CMP_PROFILE_API_ERROR, error.response.data.errors);
      });
  },

  employerMailingUpdate: ({commit}, payload) => {
    axios
      .post(constant.LOCAL_BASE_URL + constant.EMPLOYER + '/mailings/update', payload, {
        headers: {
          Authorization: 'Bearer ' + constant.USER.authorization.access_token
        }
      })
      .then(response => {
        commit(API_SUCCESSFUL_RESPONSE, response)
      })
      .catch(error => {
        commit(API_ERROR_RESPONSE, error.response.data.errors)
      });
  },


  /**
   * FREELANCER COMPANY API Integration
   */
  freelancerCompanyCreate: ({ commit }, data) => {
    axios
      .post(constant.LOCAL_BASE_URL + constant.FREELANCER_COMPANY_SIGNUP, data)
      .then(response => {
        commit(API_SUCCESSFUL_RESPONSE, response);
        commit(DISABLE, true);
        commit(ENABLE, true);
        commit(AGREEMENT, false);
      })
      .catch(error => {
        commit(API_ERROR_RESPONSE, error.response.data.errors);
      });
  },
  freelancerCompanyCreateNext: ({ commit }, data) => {
    commit(EMPLOYER, data);
    axios
      .post(constant.LOCAL_BASE_URL + constant.FREELANCER_COMPANY_SIGNUP_NEXT, data)
      .then(response => {
        commit(API_SUCCESSFUL_RESPONSE, response);
      })
      .catch(error => {
        commit(API_ERROR_RESPONSE, error.response.data.errors);
      });
  },
  freelancerCompanyGet: ({ commit }) => {
    let user = JSON.parse(localStorage.getItem('user'));
    axios
      .get(constant.LOCAL_BASE_URL + constant.FREELANCER_COMPANY + '/' + user.email, {
        headers: {
          Authorization: 'Bearer ' + user.authorization.access_token
        }
      })
      .then(response => {
        commit(FREELANCER_COMPANY, response.data);
      })
      .catch(error => {
        commit(API_ERROR_RESPONSE, error.response.data.errors);
      });
  },
  freelancerCompanyPut: ({ commit }, data) => {
    commit(EMPLOYER_REQUEST);

    let user = JSON.parse(localStorage.getItem('user'));
    axios
      .post(constant.LOCAL_BASE_URL + constant.FREELANCER_COMPANY + '/' + data.freelancer_company_id, data, {
        headers: {
          Authorization: 'Bearer ' + user.authorization.access_token
        }
      })
      .then(response => {
        commit(FREELANCER_COMPANY_API_SUCCESS, response.data);
      })
      .catch(error => {
        commit(FREELANCER_COMPANY_API_ERROR, error.response.data.errors);
      });
  },

  freelancerMailingUpdate: ({commit}, payload) => {
    axios
      .post(constant.LOCAL_BASE_URL + constant.FREELANCER + '/mailings/update', payload, {
        headers: {
          Authorization: 'Bearer ' + constant.USER.authorization.access_token
        }
      })
      .then(response => {
        commit(API_SUCCESSFUL_RESPONSE, response)
      })
      .catch(error => {
        commit(API_ERROR_RESPONSE, error.response.data.errors)
      });
  },

  /**
   * Employer JOBS API
   * @param commit
   * @param data
   */
  jobView: ({ commit }, payload) => {
    // console.log(payload);
    let user = JSON.parse(localStorage.getItem('user'));
    axios
      .get(constant.LOCAL_BASE_URL + constant.JOBS + '/' + payload.job_id, {
        headers: {
          Authorization: 'Bearer ' + user.authorization.access_token
        }
      })
      .then(response => {
        commit(JOBS_SHOW, response.data.data);
      })
      .catch(error => {
        commit(JOBS_ERROR, error.response.data.errors);
      });
  },
  jobApply: ({ commit }, payload) => {
    let user = JSON.parse(localStorage.getItem('user'));
    commit(JOB_APPLICATION_ON_LOADING, true);
    axios
      .post(constant.LOCAL_BASE_URL + constant.JOBS + '/apply/' + payload.job_id, payload.jobApplication, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': '*',
          Authorization: 'Bearer ' + user.authorization.access_token
        }
      })
      .then(response => {
        commit(JOB_APPLICATION_SUCCESS, response.data);

        if (payload.apply === true) {
          window.location.href = '/freelancer/jobs/' + payload.job_route_id;
          return;
        }
        commit(JOB_APPLICATION_ON_LOADING, false);
      })
      .catch(error => {
        commit(JOB_APPLICATION_ON_LOADING, false);
        commit(JOB_APPLICATION_ERROR, error.response.data.errors);
      });
  },
  jobBookMark: ({ commit }, payload) => {
    let user = JSON.parse(localStorage.getItem('user'));
    axios
      .post(
        constant.LOCAL_BASE_URL + constant.JOBS + '/bookmark/' + payload.job_id,
        {},
        {
          headers: {
            Authorization: 'Bearer ' + user.authorization.access_token
          }
        }
      )
      .then(response => {
        commit(JOBS_SHOW, response.data.data);
        Message.success({ message: 'Job successfully bookmarked!', offset: 150 });
      })
      .catch(error => {
        commit(JOBS_ERROR, error.response.data.errors);
      });
  },
  jobUnBookMark: ({ commit }, payload) => {
    let user = JSON.parse(localStorage.getItem('user'));
    axios
      .delete(constant.LOCAL_BASE_URL + constant.JOBS + '/bookmark/' + payload.job_id, {
        headers: {
          Authorization: 'Bearer ' + user.authorization.access_token
        }
      })
      .then(response => {
        commit(JOBS_SHOW, response.data.data);
        Message.success({ message: 'Bookmark removed!', offset: 150 });
      })
      .catch(error => {
        commit(JOBS_ERROR, error.response.data.errors);
      });
  },

  getAttachmentsData({ commit }, id) {
    let user = JSON.parse(localStorage.getItem('user'));
    axios
      .get(constant.LOCAL_BASE_URL + constant.EMPLOYER + '/attachment-info/' + id, {
        headers: {
          Authorization: 'Bearer ' + user.authorization.access_token,
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        commit(JOB_APPLICATION_ATTACHMENT, response.data);
      });
  },

  getAttachmentFile({ state }, data) {
    let user = JSON.parse(localStorage.getItem('user'));
    axios
      .get(constant.LOCAL_BASE_URL + constant.EMPLOYER + '/attachment', {
        params: {
          data
        },
        headers: {
          Authorization: 'Bearer ' + user.authorization.access_token,
          'Content-Type': 'application/json'
        },
        responseType: 'blob'
      })
      .then(response => {
        console.log('res', response);
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));

        var fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', state.applicationAttachment.label + state.applicationAttachment.extension);

        document.body.appendChild(fileLink);
        fileLink.click();
      });
  },

  loadEmployerTransactions({ commit }) {
    let user = JSON.parse(localStorage.getItem('user'));
    axios
      .get(constant.LOCAL_BASE_URL + constant.EMPLOYER + '/invoices', {
        headers: {
          Authorization: 'Bearer ' + user.authorization.access_token,
          'Content-Type': 'application/json'
        }
      })
      .then(({ data }) => {
        commit('setTransactions', data.data.transactions);
        commit('isLoaded', true);
      })
      .catch(error => {
        if (error.response.status === 404) {
          //
        }
      });
  },

  loadFreelancerTransactions({ commit }) {
    let user = JSON.parse(localStorage.getItem('user'));
    axios
      .get(constant.LOCAL_BASE_URL + constant.FREELANCER + '/invoices', {
        headers: {
          Authorization: 'Bearer ' + user.authorization.access_token,
          'Content-Type': 'application/json'
        }
      })
      .then(({ data }) => {
        commit('setTransactions', data.data.transactions);
        commit('isLoaded', true);
      })
      .catch(error => {
        if (error.response.status === 404) {
          //
        }
      });
  },

  loadFreelancerCompanyTransactions({ commit }) {
    let user = JSON.parse(localStorage.getItem('user'));
    axios
      .get(constant.LOCAL_BASE_URL + constant.FREELANCER_COMPANY + '/invoices', {
        headers: {
          Authorization: 'Bearer ' + user.authorization.access_token,
          'Content-Type': 'application/json'
        }
      })
      .then(({ data }) => {
        commit('setTransactions', data.data.transactions);
        commit('isLoaded', true);
      })
      .catch(error => {
        if (error.response.status === 404) {
          //
        }
      });
  },

  forgotPassword({ commit }, email) {
    axios
      .post(
        LOCAL_BASE_URL + OAUTH + '/password/email',
        {
          email: email
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .then(response => {
        if (response.data.success) {
          commit(FORGOT_SUCCESS, response.data);
        }
      })
      .catch(error => {
        commit(FORGOT_ERROR, error.response.data[0]);
      });
  },

  resetPassword({ commit }, data) {
    axios
      .post(LOCAL_BASE_URL + OAUTH + '/password/reset', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        commit(RESET_SUCCESS, response.data.success);
      })
      .catch(error => {
        let response = '';
        if (typeof error.response.data.errors === 'undefined') {
          response = error.response.data.error;
        }
        if (typeof error.response.data.error === 'undefined') {
          response = error.response.data.errors.password[0];
        }
        commit(RESET_ERROR, {
          response: response,
          error: true
        });
      });
  },

  loadFreelancerCategory({ commit }) {
    let user = JSON.parse(localStorage.getItem('user'));
    axios
      .get(constant.LOCAL_BASE_URL + '/api/v1/skills/categories/popular', {
        headers: {
          Authorization: 'Bearer ' + user.authorization.access_token,
          'Content-Type': 'application/json'
        }
      })
      .then(({ data }) => {
        commit('setCategory', data.data);
      })
      .catch(error => {
        if (error.response.status === 404) {
          //
        }
      });
  },

  loadFreelancerUserSkill({ commit }) {
    let user = JSON.parse(localStorage.getItem('user'));
    axios
      .get(constant.LOCAL_BASE_URL + `/api/v1/freelancer/${user.freelancer_id}/skills`, {
        headers: {
          Authorization: 'Bearer ' + user.authorization.access_token,
          'Content-Type': 'application/json'
        }
      })
      .then(({ data }) => {
        commit('getUserSkill', data.data);
      })
      .catch(error => {
        if (error.response.status === 404) {
          //
        }
      });
  },

  getCountry: ({ commit }) => {
    axios
      .get(constant.LOCAL_BASE_URL + constant.FREELANCER_SIGNUP + '/location')
      .then(response => {
        commit(COUNTRY, response.data);
      })
      .catch(error => {
        commit(COUNTRY, error);
      });
  },

  getInvoices({ commit }) {
    let user = JSON.parse(localStorage.getItem('user'));
    return axios
      .get(`${constant.LOCAL_BASE_URL}/api/v1/employer/invoices`, {
        params: user,
        headers: {
          Authorization: 'Bearer ' + user.authorization.access_token,
          'Content-Type': 'application/json'
        }
      })
      .then(d => {
        commit(EMPLOYER_ADD_TRANSACTION, d.data.data);
        return d.data;
      });
  },

  getFreeSubscriptionCount({ commit }) {
    return axios.get(constant.LOCAL_BASE_URL + constant.FREE_SUBS_COUNT).then(response => {
      commit(FREE_SUBSCRIPTIONS_COUNT, response.data);
    });
  },

  setFreelancerProfileProgress({ commit }, data) {
    commit(FREELANCER_PROFILE_PROGRESS, data);
  },

  async getEmployerBookmarkedFreelancers({ commit }, page) {
    let user = JSON.parse(localStorage.getItem('user'));
    let response = await axios.get(constant.LOCAL_BASE_URL + constant.EMPLOYER_BOOKMARKED_FREELANCERS + '?page=' + page, {
      headers: {
        Authorization: 'Bearer ' + user.authorization.access_token
      }
    });
    commit(EMPLOYER_BOOKMARKED_FREELANCERS, response.data);

    return response.data;
  },

  verifyEmail({commit}, token) {
    return axios.post(LOCAL_BASE_URL + '/' + OAUTH_VERIFY_EMAIL +
        token)
        .then(response => {
          commit(EMAIL_VERIFY_RESP, response.data);
        })
        .catch(error => {
          commit(EMAIL_VERIFY_ERR, error.response.data.message);
        });
  },

  async inviteSignup({commit}, data) {
    commit(INVITE_INFORMATION_RESP,null)
    commit(INVITE_INFORMATION_ERR,null)

    await axios
    .post(LOCAL_BASE_URL+'/api/v1/signup/invite-only', data)
    .then(response => {
        commit(INVITE_INFORMATION_RESP,response.data)
        commit(INVITE_INFORMATION_ERR,null)
        return response.data;
      })
      .catch(error => {
        commit(INVITE_INFORMATION_ERR, error.response.data.errors);
        return error.response.data.errors;
      });
  }
};
