import getUser from '~/helpers/getUser'
import getSignUpDetails from '~/helpers/getSignUpDetails'

export default () => {
  const user = getUser()
  if (user) {
    return user.authorization.access_token
  }

  const freelancerSignUp = getSignUpDetails('freelancer')

  if (freelancerSignUp) {
    return freelancerSignUp.token
  }

  const employerSignUp = getSignUpDetails('employer')

  if (employerSignUp) {
    return employerSignUp.token
  }

  return null
}
