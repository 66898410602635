import getUser from '~/helpers/getUser'

const USER = getUser()

export default () => {
  if (! USER) {
    return
  }

  return USER.provider
}