export const FREELANCER = 'FREELANCER';
export const FREELANCER_REQUEST = 'FREELANCER_REQUEST';
export const FREELANCER_API_SUCCESS = 'FREELANCER_API_SUCCESS';
export const FREELANCER_API_ERROR = 'FREELANCER_API_ERROR';
export const FREELANCER_BIO_API_SUCCESS = 'FREELANCER_BIO_API_SUCCESS';
export const FREELANCER_BIO_API_ERROR = 'FREELANCER_BIO_API_ERROR';
export const FREELANCER_BIO_ADDRESS_SUCCESS = 'FREELANCER_BIO_ADDRESS_SUCCESS';
export const FREELANCER_BIO_ADDRESS_ERROR = 'FREELANCER_BIO_ADDRESS_ERROR';
export const FREELANCER_ADDRESS_API_SUCCESS = 'FREELANCER_ADDRESS_API_SUCCESS';
export const FREELANCER_ADDRESS_API_ERROR = 'FREELANCER_ADDRESS_API_ERROR';

export const FREELANCER_COMPANY = 'FREELANCER_COMPANY';
export const FREELANCER_COMPANY_REQUEST = 'FREELANCER_COMPANY_REQUEST';
export const FREELANCER_COMPANY_API_SUCCESS = 'FREELANCER_COMPANY_API_SUCCESS';
export const FREELANCER_COMPANY_API_ERROR = 'FREELANCER_COMPANY_API_ERROR';
export const FREELANCER_UPDATE_SKILLS = 'FREELANCER_UPDATE_SKILLS';
export const FREELANCER_REMOVE_SKILLS = 'FREELANCER_REMOVE_SKILLS';
export const FREELANCER_UPDATE_PROFILE = 'FREELANCER_UPDATE_PROFILE';
export const FREELANCER_UPDATE_PROFILE_PHOTO = 'FREELANCER_UPDATE_PROFILE_PHOTO';
export const FREELANCER_JOB_APPLICATION_ON_UPLOADING = 'FREELANCER_JOB_APPLICATION_ON_UPLOADING';

export const EMPLOYER = 'EMPLOYER';
export const EMPLOYER_REQUEST = 'EMPLOYER_REQUEST';
export const EMPLOYER_API_SUCCESS = 'EMPLOYER_API_SUCCESS';
export const EMPLOYER_API_ERROR = 'EMPLOYER_API_ERROR';
export const EMPLOYER_CMP_PROFILE_API_ERROR = 'EMPLOYER_CMP_PROFILE_API_ERROR';
export const EMPLOYER_UPDATE_ACCT_DETAILS = 'EMPLOYER_UPDATE_ACCT_DETAILS';
export const EMPLOYER_API_ON_SUCCESS = 'EMPLOYER_API_ON_SUCCESS';
export const EMPLOYER_ON_REQUEST = 'EMPLOYER_ON_REQUEST';
export const EMPLOYER_PROFILE_ON_REQUEST = 'EMPLOYER_PROFILE_ON_REQUEST';
export const EMPLOYER_PROFILE_ON_UPDATE = 'EMPLOYER_PROFILE_ON_UPDATE';
export const EMPLOYER_ADD_TRANSACTION = 'EMPLOYER_ADD_TRANSACTION';
export const EMPLOYER_COMPANY_UPDATE_DATE_PROPS = 'EMPLOYER_COMPANY_UPDATE_DATE_PROPS';
export const EMPLOYER_BOOKMARKED_FREELANCERS = 'EMPLOYER_BOOKMARKED_FREELANCERS';

export const JOBS_SHOW = 'JOBS_SHOW';
export const JOBS_ERROR = 'JOBS_ERROR';

export const JOB_APPLICATION_ERROR = 'JOB_APPLICATION_ERROR';
export const JOB_APPLICATION_SUCCESS = 'JOB_APPLICATION_SUCCESS';
export const JOB_APPLICATION_ON_LOADING = 'JOB_APPLICATION_ON_LOADING';

export const API_ERROR_RESPONSE = 'API_ERROR_RESPONSE';
export const API_SUCCESSFUL_RESPONSE = 'API_SUCCESSFUL_RESPONSE';
export const JOB_APPLICATION_ATTACHMENT = 'JOB_APPLICATION_ATTACHMENT';

export const DISABLE = 'DISABLE';
export const ENABLE = 'ENABLE';
export const AGREEMENT = 'AGREEMENT';

export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';

export const FORGOT_SUCCESS = 'FORGOT_SUCCESS';
export const FORGOT_ERROR = 'FORGOT_ERROR';

export const RESET_SUCCESS = 'RESET_SUCCESS';
export const RESET_ERROR = 'RESET_ERROR';

export const PRESENTATION_REQUEST = 'PRESENTATION_REQUEST';
export const PRESENTATION_ERROR = 'PRESENTATION_ERROR';
export const PRESENTATION_SUCCESS = 'PRESENTATION_SUCCESS';

export const COUNTRY = 'COUNTRY';

export const CONTACT_REQUEST = 'CONTACT_REQUEST';
export const CONTACT_ERROR = 'CONTACT_ERROR';
export const CONTACT_SUCCESS = 'CONTACT_SUCCESS';

export const CONCIERGE_REQUEST = 'CONCIERGE_REQUEST';
export const CONCIERGE_ERROR = 'CONCIERGE_ERROR';
export const CONCIERGE_SUCCESS = 'CONCIERGE_SUCCESS';

export const CITRONWORKERS_REQUEST = 'CITRONWORKERS_REQUEST';
export const CITRONWORKERS_ERROR = 'CITRONWORKERS_ERROR';
export const CITRONWORKERS_SUCCESS = 'CITRONWORKERS_SUCCESS';

export const FREE_SUBSCRIPTIONS_COUNT = 'FREE_SUBSCRIPTIONS_COUNT';
export const FREELANCER_PROFILE_PROGRESS = 'FREELANCER_PROFILE_PROGRESS';

export const EMAIL_VERIFY_RESP = 'EMAIL_VERIFY_RESP';
export const EMAIL_VERIFY_ERR = 'EMAIL_VERIFY_ERR';

export const INVITE_INFORMATION_RESP = 'INVITE_INFORMATION_RESP';
export const INVITE_INFORMATION_ERR = 'INVITE_INFORMATION_ERR';
export const INVITE_INFORMATION_FORM = 'INVITE_INFORMATION_FORM';