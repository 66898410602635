<template>
  <el-dialog
    title="Signup on CitronWorks"
    :visible.sync="visible"
    width="500px"
    class="signup-modal"
    center
  >
    <span slot="title" class="dialog-title">
      <h4 class="orange-text bold">Signup</h4>
    </span>
    <span>
      <a href="/email-signup?role=Employer">
        <el-button
          type="warning"
          class="w-100 mb-3"
          @click="hideModal"
        >
          Sign up as an Employer
        </el-button>
      </a>
    </span>
    <span>
      <a href="/email-signup?role=Freelancer">
        <el-button
          type="warning"
          plain
          class="w-100"
          @click="hideModal"
        >
          Sign up as a Freelancer
        </el-button>
      </a>
    </span>  
  </el-dialog>
</template>

<script>
  export default {
    props: {
      value: Boolean
    },
    computed: {
      visible: {get() {return this.value}, set(value) {this.$emit('input', value)}}
    },
    methods: {
      hideModal() {
        this.$emit('hide')
      }
    }
  }
</script>