//mutation.js
import * as types from './mutation_types';

export default {
  /**
   * Freelancer
   *
   * @param state
   * @param data
   */
  [types.FREELANCER](state, data) {
    state.freelancer.info = data;
  },
  [types.FREELANCER_REQUEST](state) {
    state.status = 'loading';
  },
  [types.FREELANCER_API_SUCCESS](state, data) {
    state.status = 'success';
    state.freelancer.errorResp = '';
    state.freelancer.successResp = data;
  },
  [types.FREELANCER_API_ERROR](state, data) {
    state.status = 'error';
    state.freelancer.errorResp = data;
    state.freelancer.successResp = '';
  },

  [types.FREELANCER_BIO_API_SUCCESS](state, data) {
    state.status = 'success';
    state.freelancer.billingError = '';
    state.freelancer.billingResp = data;
  },
  [types.FREELANCER_BIO_API_ERROR](state, data) {
    state.status = 'error';
    state.freelancer.billingResp = '';
    state.freelancer.billingError = data;
  },

  [types.FREELANCER_BIO_ADDRESS_SUCCESS](state, data) {
    state.status = 'success';
    state.freelancer.addressErrorResp = '';
    state.freelancer.addressSuccessResp = data;
  },
  [types.FREELANCER_BIO_ADDRESS_ERROR](state, data) {
    state.status = 'error';
    state.freelancer.addressErrorResp = data;
    state.freelancer.addressSuccessResp = '';
  },

  [types.FREELANCER_ADDRESS_API_SUCCESS](state, data) {
    state.status = 'success';
    state.freelancer.addressSuccess = data;
    state.freelancer.addressError = '';
  },
  [types.FREELANCER_ADDRESS_API_ERROR](state, data) {
    state.status = 'error';
    state.freelancer.addressSuccess = '';
    state.freelancer.addressError = data;
  },
  [types.FREELANCER_UPDATE_SKILLS](state, data) {
    state.freelancer.info.freelancer_skills = [...state.freelancer.info.freelancer_skills, data];
  },
  [types.FREELANCER_REMOVE_SKILLS](state, data) {
    state.freelancer.info.freelancer_skills = state.freelancer.info.freelancer_skills.filter(d => d.skill_id !== data);
  },
  [types.FREELANCER_UPDATE_PROFILE](state, d) {
    state.freelancer.info.profile = d;
  },
  [types.FREELANCER_UPDATE_PROFILE_PHOTO](state, d) {
    state.freelancer.info.profile_photo = d;
  },

  [types.FREELANCER_PROFILE_PROGRESS](state, d) {
    state.freelancer.progress = d;
  },
  [types.FREELANCER_JOB_APPLICATION_ON_UPLOADING](state, d) {
    state.jobApplicationOnUploading = d;
  },

  detailSuccess: (state, status) => (state.freelancer.detailSuccess = status),
  cleanError: (state, status) => (state.freelancer.errorResp = status),
  cleanBioError: (state, status) => (state.freelancer.billingError = status),
  cleanAddrError: (state, status) => (state.freelancer.addressError = status),
  bioSuccess: (state, status) => (state.freelancer.bioSuccess = status),
  passwordSuccess: (state, status) => (state.freelancer.passwordSuccess = status),
  clearApiError: (state, status) => (state.apiError = status),
  clearBioError: (state, status) => (state.freelancer.billingError = status),
  clearAddressError: (state, status) => (state.freelancer.addressErrorResp = status),
  addressSuccessResp: (state, status) => (state.freelancer.addressSuccessResp = status),

  /**
   * Employer
   * @param state
   * @param data
   */
  [types.EMPLOYER](state, data) {
    state.employer = data;
  },
  [types.EMPLOYER_REQUEST](state) {
    state.status = 'loading';
  },
  [types.EMPLOYER_API_SUCCESS](state, data) {
    state.status = 'success';
    state.employerErr = '';
    state.employerSuccess = data;
  },

  [types.EMPLOYER_ON_REQUEST](state, v) {
    state.status = v;
  },

  [types.EMPLOYER_API_ON_SUCCESS](state, v) {
    state.employerSuccess = v;
  },

  [types.EMPLOYER_API_ERROR](state, data) {
    state.status = 'error';
    state.employerErr = data;
    state.employerSuccess = '';
  },
  [types.EMPLOYER_CMP_PROFILE_API_ERROR](state, data) {
    state.status = 'error';
    state.employerErrCmpProfileErr = data;
    state.employerSuccess = '';
  },
  [types.EMPLOYER_UPDATE_ACCT_DETAILS](state, d) {
    state.employer = { ...state.employer, ...d };
  },
  [types.EMPLOYER_PROFILE_ON_REQUEST](state, data) {
    state.employerProfileActions.isLoading = data;
  },
  [types.EMPLOYER_PROFILE_ON_UPDATE](state, d) {
    state.employerProfileActions.isSuccess = d;
  },
  employerDetailSuccess: (state, status) => (state.employerSuccess = status),
  companyProfileSuccess: (state, status) => (state.employerSuccess = status),
  employerErr: (state, status) => (state.employerErr = status),

  [types.EMPLOYER_ADD_TRANSACTION](state, d) {
    state.transactions = d;
  },
  [types.EMPLOYER_COMPANY_UPDATE_DATE_PROPS](state, d) {
    state.employer.company = d;
  },

  [types.EMPLOYER_BOOKMARKED_FREELANCERS](state, d) {
    state.employerBookmarkedFreelancers = d;
  },

  /**
   * Freelancer Company
   * @param state
   * @param data
   */
  [types.FREELANCER_COMPANY](state, data) {
    state.freelancerCompany = data;
  },
  [types.FREELANCER_COMPANY_REQUEST](state, data) {
    state.status = 'loading';
    state.freelancerCompany = data;
  },
  [types.FREELANCER_COMPANY_API_ERROR](state, data) {
    state.status = 'success';
    state.freelancerCompany = data;
  },
  [types.FREELANCER_COMPANY_API_SUCCESS](state, data) {
    state.status = 'error';
    state.freelancerCompany = data;
  },

  [types.API_ERROR_RESPONSE](state, data) {
    state.apiError = data;
    state.apiSuccess = null;
  },
  [types.API_SUCCESSFUL_RESPONSE](state, data) {
    state.apiSuccess = data;
    state.apiError.check_password = '';
  },

  // [types.JOBS](state, data) {
  //     state.jobs = data;
  // },
  [types.JOBS_SHOW](state, data) {
    state.jobs.show = data;
  },
  // [types.JOBS_SUCCESS](state, data) {
  //     state.jobs.error = false;
  //     state.jobs.success = data;
  // },
  [types.JOBS_ERROR](state, data) {
    state.jobs.success = false;
    state.jobs.error = data;
  },

  [types.JOB_APPLICATION_SUCCESS](state, data) {
    state.jobApplicationErr = '';
    state.jobApplicationSuccess = data;
  },

  [types.JOB_APPLICATION_ON_LOADING](state, event) {
    state.jobApplicationOnLoading = event;
  },

  [types.JOB_APPLICATION_ERROR](state, data) {
    state.jobApplicationSuccess = '';
    state.jobApplicationErr = data;
  },

  [types.JOB_APPLICATION_SUCCESS](state, data) {
    state.jobApplicationErr = '';
    state.jobApplicationSuccess = data;
  },

  [types.DISABLE](state, data) {
    state.disable = data;
  },
  [types.ENABLE](state, data) {
    state.enable = data;
  },
  [types.AGREEMENT](state, data) {
    state.agreement = data;
  },

  [types.AUTH_REQUEST]: state => {
    state.status = 'loading';
  },
  [types.AUTH_SUCCESS]: (state, auth) => {
    state.status = 'success';
    state.auth = auth;
  },
  [types.AUTH_ERROR]: (state, error) => {
    state.status = 'error';
    state.authError = error;
  },

  [types.FORGOT_SUCCESS]: (state, data) => {
    state.passwordForgot.success = data;
  },
  [types.FORGOT_ERROR]: (state, data) => {
    state.passwordForgot.error = data;
  },
  [types.RESET_SUCCESS]: (state, data) => {
    state.passwordReset.success = data;
    state.passwordReset.error = '';
  },
  [types.RESET_ERROR]: (state, data) => {
    state.passwordReset.success = '';
    state.passwordReset.error = data.error;
    state.passwordReset.response = data.response;
  },
  setTransactions: (state, transactions) => (state.transactions = transactions),
  isLoaded: (state, loaded) => (state.loaded = loaded),

  setCategory(state, payload) {
    state.category = payload;
  },

  getUserSkill(state, payload) {
    state.userskill = payload;
  },

  [types.PRESENTATION_REQUEST]: state => {
    state.status = 'loading';
  },
  [types.PRESENTATION_SUCCESS]: (state, data) => {
    state.status = 'success';
    state.presentation = data;
    state.presentation.error = '';
  },
  [types.PRESENTATION_ERROR]: (state, data) => {
    state.status = 'error';
    state.presentation.error = data;
  },

  [types.COUNTRY]: (state, data) => {
    state.country = data;
  },

  [types.CONTACT_REQUEST]: state => {
    state.status = 'loading';
  },
  [types.CONTACT_ERROR]: (state, data) => {
    state.status = 'error';
    state.contact.error = data;
    state.contact.success = '';
  },
  [types.CONTACT_SUCCESS]: (state, data) => {
    state.status = 'success';
    state.contact.success = data;
    state.contact.error = '';
  },

  [types.CONCIERGE_REQUEST]: state => {
    state.status = 'loading';
  },
  [types.CONCIERGE_ERROR]: (state, data) => {
    state.status = 'error';
    state.conciergeError = data;
    state.contact.success = '';
  },
  [types.CONCIERGE_SUCCESS]: (state, data) => {
    state.status = 'success';
    state.concierge = data;
    state.conciergeError = '';
  },

  [types.CITRONWORKERS_REQUEST]: state => {
    state.status = 'loading';
  },
  [types.CITRONWORKERS_ERROR]: (state, data) => {
    state.status = 'error';
    state.citronworkersError = data;
    state.citronworkers.success = '';
  },
  [types.CITRONWORKERS_SUCCESS]: (state, data) => {
    state.status = 'success';
    state.citronworkers = {
      first_name: '',
      last_name: '',
      email: '',
      company_name: '',
      company_website: '',
      phone: '',
      message: '',
      referrer: '',
      member_category: 'employer',
      agreement: false,
      concierge_subscribe: false,
      successMessage: 'Thanks for signing up ' + data.first_name + '! One of our representatives will get back to you as soon as possible.'
    };
    state.citronworkersError = '';
  },
  [types.JOB_APPLICATION_ATTACHMENT]: (state, data) => {
    state.applicationAttachment = data;
  },

  [types.FREE_SUBSCRIPTIONS_COUNT]: (state, data) => {
    state.freeSubscriptionCount = data.count;
  },

  [types.EMAIL_VERIFY_RESP]: (state, data) => {
    state.emailVerifyResp = data;
  },

  [types.EMAIL_VERIFY_ERR]: (state, data) => {
    state.emailVerifyErr = data;
  },

  [types.INVITE_INFORMATION_RESP]: (state, data) => {
    state.inviteUserResp = data;
  },

  [types.INVITE_INFORMATION_ERR]: (state, data) => {
    state.inviteUserErr = data;
  },

  [types.INVITE_INFORMATION_FORM]: (state, data) => {
    state.inviteUserForm = data;
  },
};
