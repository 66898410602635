<template>
  <div id="app">

    <nav class="navbar navbar-expand-md sticky navbar-light bg-light" :class="['/concierge', '/citronworkers'].includes($route.path) ? 'no-shadow' : ''">
      <div class="container">
        <a :href="logoLinkUrl" class="navbar-brand">
          <object type="image/svg+xml" data="/images/logo.svg" height="40" width="auto" style="pointer-events: none;">
          <img src="/images/logo.svg" width="auto" height="40" alt="CitronWorks" />
          </object>
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" v-if="! ['/freelancersignup', '/employer/signup'].includes($route.path)">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav" v-if="! ['/freelancersignup', '/employer/signup'].includes($route.path)" :class="['/concierge'].includes($route.path) ? 'concierge-menu' : ''">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item" v-if="!auth && !user">
              <a href="/" class="nav-link" exact>Home <span class="sr-only">(current)</span></a>
            </li>
            
            <!-- <li class="nav-item" v-if="!auth && !user">
              <a class="nav-link" href="/marketplace">Employers</a>
            </li>
            <li class="nav-item" v-if="!auth && !user">
              <a class="nav-link" href="/freelancers">Freelancers</a>
            </li> -->
            <!-- <li class="nav-item dropdown" v-if="!auth && !user" :class="['/', '/concierge'].includes($route.path) ? 'transparent-dropdown' : ''">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                About Us
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <a class="dropdown-item" href="/about-us">About CitronWorks</a>
                <a class="dropdown-item" href="/employers">For Employers</a>
                <a class="dropdown-item" href="/freelancers">For Freelancers</a>
              </div>
            </li> -->
            <!-- <li class="nav-item dropdown" v-if="!auth && !user" :class="['/', '/concierge', '/citronworkers'].includes($route.path) ? 'transparent-dropdown' : ''">
              <a class="nav-link dropdown-toggle" href="/employers" id="services" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Employers
              </a>
              <a class="nav-link dropdown-toggle" href="/employers" id="services" role="button" aria-haspopup="true" aria-expanded="false">
                Employers
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <a class="dropdown-item" href="/citronworkers">Remote Team Management</a>
                <a class="dropdown-item" href="/concierge">Employer Concierge</a>
                <a class="dropdown-item" href="/marketplace">Employer Marketplace</a>
              </div>
            </li> -->
            <li class="nav-item" v-if="!auth && !user">
              <a class="nav-link" href="/about-us">About Us</a>
            </li>
            <li class="nav-item" v-if="!auth && !user">
              <a href="/plans" class="nav-link">Plans</a>
            </li>
            <li class="nav-item" v-if="!auth && !user">
              <a href="https://thankz.com" target="_blank" class="nav-link">Concierge</a>
            </li>
            <li class="nav-item" v-if="!auth && !user">
              <a href="http://blog.citronworks.com/" class="nav-link">Blog</a>
            </li>
            <li class="nav-item visible-xs" v-if="auth && user">
              <a class="nav-link" href="/feed" exact v-if="isFreelancer">Dashboard</a>
              <a class="nav-link" href="/employer/feed" exact v-if="isEmployer">Dashboard</a>
            </li>
            <li class="nav-item visible-xs" v-if="auth && isEmployer">
              <a class="nav-link" href="/employer/freelancers" exact>Freelancers</a>
            </li>
            <li class="nav-item visible-xs" v-if="auth && isEmployer">
              <a class="nav-link" href="/employer/bookmarked-freelancers" exact>Bookmarked Freelancers</a>
            </li>
            <li class="nav-item visible-xs" v-if="auth && user">
              <a class="nav-link" href="/freelancer/jobs" exact v-if="isFreelancer">Jobs Search</a>
              <a class="nav-link" href="/employer/jobs" exact v-if="isEmployer">Jobs</a>
            </li>
            <li class="nav-item visible-xs" v-if="auth && isFreelancer">
              <a class="nav-link" href="/freelancer/jobs/applied" exact>My Applied Jobs</a>
            </li>
            <li class="nav-item visible-xs" v-if="auth && isFreelancer">
              <a class="nav-link" href="/freelancer/jobs/bookmarked" exact>My Bookmarked Jobs</a>
            </li>
            <li class="nav-item visible-xs" v-if="auth && user">
              <a href="/conversations" class="nav-link">
                Messages
                <span v-if="numUnreadConversations" class="badge badge-danger badge-pill" v-text="numUnreadConversations" />
              </a>
            </li>
            <li class="nav-item visible-xs" v-if="auth && isEmployer">
              <a href="/employer/transactions" class="nav-link">Transactions</a>
            </li>
            <li class="nav-item visible-xs" v-if="auth && isEmployer">
              <a href="/employer/billing" class="nav-link">Billing</a>
            </li>
            <!-- <li class="nav-item visible-xs" v-if="auth && user">
              <a class="nav-link" href="/addons" exact>Add-ons</a>
            </li> -->
            <li class="nav-item visible-xs" v-if="auth && user">
              <a href="/accountdetails" class="nav-link" v-if="isFreelancer">My Profile</a>
              <a href="/employer/accountdetails" class="nav-link" v-if="isEmployer">My Profile</a>
            </li>
            <li class="nav-item visible-xs" v-if="auth && isFreelancer">
              <a href="/my-skills/skill-search" class="nav-link">My Skills</a>
            </li>
            <li class="nav-item visible-xs" v-if="auth && isEmployer">
              <a href="/employer/companyinformation" class="nav-link">Company Profile</a>
            </li>

            <li class="nav-item">
              <a class="nav-link" href="/contact-form" v-if="! ['/upgrading'].includes($route.path)">Contact</a>
              <el-dialog
                      :visible.sync="contact"
                      :modalAppendToBody="false"
                      width="50%">
                          <span slot="title" class="dialog-title">
                            <h4 class="bold orange-text">Contact Form</h4>
                          </span>
                <contact-form></contact-form>
                <span slot="footer" class="dialog-footer text-center">
                            <el-button @click="contact = false" type="warning">Close</el-button>
                          </span>
              </el-dialog>
            </li>
            <li class="nav-item" v-if="auth && user && ! ['/upgrading'].includes($route.path)">
              <a href="#" v-on:click="logout()" class="nav-link">Logout</a>
            </li>
            <li class="nav-item" v-if="!auth && !user">
              <a class="nav-link" href="/login" exact>Login</a>
            </li>
            <li class="nav-item" v-if="!auth && !user">
              <el-button v-if="$route.path == '/concierge'" type="text" class="nav-link concierge-btn hidden-xs" v-scroll-to="{ element: '.form',  duration: 2000 }">Concierge Signup</el-button>
              <el-button v-if="$route.path == '/concierge'" type="text" class="nav-link concierge-btn visible-xs" v-scroll-to="{ element: '.form',  duration: 2000, offset: -380, }">Concierge Signup</el-button>
              <el-button v-else type="text" @click="dialogVisible = true" class="nav-link">Signup</el-button>
            </li>
            
          </ul>
        </div>
        <div class="collapse navbar-collapse" id="navbarNav" v-if="['/freelancersignup', '/employer/signup'].includes($route.path)">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item">
              <a v-if="$route.path != '/'" class="nav-link" href="#" @click="contact = true">Contact</a>
              <a v-else href="#about" class="nav-link" v-scroll-to="{ element: '#signup',  duration: 2000 }">Contact</a>
              <el-dialog
                      :visible.sync="contact"
                      :modalAppendToBody="false"
                      width="50%">
                          <span slot="title" class="dialog-title">
                            <h4 class="bold orange-text">Contact Form</h4>
                          </span>
                <contact-form></contact-form>
                <span slot="footer" class="dialog-footer text-center">
                            <el-button @click="contact = false" type="warning">Close</el-button>
                          </span>
              </el-dialog>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <generic-sign-up-modal v-model="dialogVisible"/>
    
    <router-view/>
    <div class="footer container" v-if="!auth && !user && ! ['/upgrading'].includes($route.path)">
      <div class="row mb-5 justify-content-center">
        <div class="col-md-2 col-lg-2 col-sm-3 col-1 text-center hidden-xs">
          <img class="img mb-4" src="/images/logo.png" width="auto" height="50" alt="Image" />
        </div>
        <div class="col-md-2 col-lg-2 col-sm-3 col-6 text-left">
          <ul class="">
            <li class="bold">Company</li>
            <li><a href="/about-us">About Us</a></li>
            <li><a href="/freelancers">For Freelancers</a></li>
          </ul>
        </div>
        <div class="col-md-3 col-lg-3 col-sm-3 col-6 text-left">
          <ul class="">
            <li class="bold">Employer Services</li>
            <li><a href="/marketplace">Marketplace</a></li>
            <li><a href="/concierge">Employer Concierge</a></li>
            <li><a href="https://www.thankz.com/">Managed services - Thankz.com</a></li>
          </ul>
        </div>
        <div class="col-md-2 col-lg-2 col-sm-3 col-6 text-left">
          <ul class="">
            <li class="bold">Legal</li>
            <li>
              <router-link to="/terms-of-service">Terms of Service</router-link>
            </li>
            <li>
              <router-link to="/privacy-policy">Privacy Policy</router-link>
            </li>
          </ul>
        </div>
        <div class="col-md-2 col-lg-2 col-sm-3 col-6 text-left">
          <ul class="">
            <li class="bold">Extras</li>
            <li><a href="http://blog.citronworks.com/">Blog</a></li>
            <li><a href="/plans">Plans</a></li>
            <li><a href="/contact-form">Contact</a></li>
          </ul>
        </div>
        <div class="col-12 mt-4 text-center" v-if="! ['/freelancersignup', '/employer/betasignup', '/freelancer_company/betasignup'].includes($route.path)">
          <ul>
            <li class="bold">Follow</li>
            <li>
              <a href="https://www.facebook.com/citronworksinc" target="_blank">
                <img src="/images/fb.png" height="30" width="30"> 
              </a>
            <a href="https://www.linkedin.com/company/citronworks" target="_blank" style="margin-left: 7px;"><img src="/images/linkedin.png" height="30" width="30"></a>
            <a href="https://www.instagram.com/citronworksinc" target="_blank" style="margin-left: 7px;"><img src="/images/instagram.png" height="30" width="30"></a>
            <a href="https://www.twitter.com/citronworksinc" target="_blank" style="margin-left: 7px;"><img src="/images/twitter.png" height="30" width="30"></a>
            </li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <div class="footer-text">
            <p class="small mb-1">
              Copyright © 2020 CitronWorks, Inc. All Rights Reserved.
            </p>
            <p class="small">11693 San Vicente Blvd, Ste #182, Los Angeles, CA 90049 USA</p>

          </div>
        </div>
      </div>
    </div>
    <div class="footer container" v-if="auth && user && ! ['/upgrading'].includes($route.path)">
      <div class="row mb-5 justify-content-center">
        <div class="col-md-2 col-lg-2 col-sm-3 col-1 text-center hidden-xs">
          <img class="img mb-4" src="/images/logo.png" width="auto" height="50" alt="Image" />
        </div>
        <div class="col-md-2 col-lg-2 col-sm-3 col-6 text-left">
          <ul class="">
            <li class="bold">Legal</li>
            <li>
              <router-link to="/terms-of-service">Terms of Service</router-link>
            </li>
            <li>
              <router-link to="/privacy-policy">Privacy Policy</router-link>
            </li>
          </ul>
        </div>
        <div class="col-md-2 col-lg-2 col-sm-3 col-6 text-left">
          <ul class="">
            <li class="bold">Extras</li>
            <li><a href="/contact-form">Contact</a></li>
          </ul>
        </div>
        <div class="col-12 mt-4 text-center" v-if="! ['/freelancersignup', '/employer/betasignup', '/freelancer_company/betasignup'].includes($route.path)">
          <ul>
            <li class="bold">Follow</li>
            <li><a href="https://www.facebook.com/citronworksinc" target="_blank"><img src="/images/fb.png" height="30" width="30"></a>
            <a href="https://www.linkedin.com/company/citronworks" target="_blank" style="margin-left: 7px;"><img src="/images/linkedin.png" height="30" width="30"></a>
            <a href="https://www.instagram.com/citronworksinc" target="_blank" style="margin-left: 7px;"><img src="/images/instagram.png" height="30" width="30"></a>
            <a href="https://www.twitter.com/citronworksinc" target="_blank" style="margin-left: 7px;"><img src="/images/twitter.png" height="30" width="30"></a>
            </li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <div class="footer-text">
            <p class="small mb-1">
              Copyright © 2020 CitronWorks, Inc. All Rights Reserved.
            </p>
            <p class="small">11693 San Vicente Blvd, Ste #182, Los Angeles, CA 90049 USA</p>

          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>
<script>
// import HelloWorld from './components/HelloWorld.vue'
import './scss/style.scss'
import store from './vuex/store';
import axios from "axios";
import { mapGetters, mapActions } from 'vuex';
import Stickyfill from 'stickyfilljs'
import $ from 'jquery'
import ContactForm from '~/components/ContactForm'
import GenericSignUpModal from '~/components/GenericSignUpModal'
import getUserType from '~/helpers/getUserType'


export default {
  store,
  name: 'app',
  components: {
    ContactForm,
    GenericSignUpModal
  },
  data() {
    return {
      contact: false,
      dialogVisible: false,
      user: JSON.parse(localStorage.getItem('user')) === undefined ? null : JSON.parse(localStorage.getItem('user'))
    }
  },
  computed: {
    ...mapGetters({
      auth:'isAuthenticated',
      numUnreadConversations: 'messenger/numUnread',
    }),

    logoLinkUrl () {
      if (getUserType() === 'employer') {
        return '/employer/feed'
      } else if (getUserType() === 'freelancer') {
        return '/feed'
      } else {
        return '/'
      }
    },
    isEmployer () {
      return this.auth && getUserType() === 'employer'
    },

    isFreelancer () {
      return this.auth && getUserType() === 'freelancer'
    },
  },
  created () {
    axios.interceptors.response.use(undefined, function (err) {
      return new Promise(function () {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          // if you ever get an unauthorized, logout the user
          this.$store.dispatch('logoutABC')
          // you can also redirect to /login if needed !
        }
        throw err;
      });
    });
  },
  mounted () {
    const elements = document.querySelectorAll('.sticky')
    Stickyfill.add(elements)

    $(function () {
      $(document).scroll(function () {
        var $nav = $(".no-shadow");
        $nav.toggleClass('scrolled', $(this).scrollTop() > $nav.height());
      });
    });

    if (window.innerWidth > 768) {
      $('ul.navbar-nav li.dropdown').hover(function() {
        $(this).find('.dropdown-menu').stop(true, true).delay(100).fadeIn(300);
      }, function() {
        $(this).find('.dropdown-menu').stop(true, true).delay(100).fadeOut(300);
      });
    } else {
      $(this).find('.dropdown-menu').stop(true, true).delay(100).fadeIn(300);
    }

    $('.navbar-nav>li>button').on('click', function(){
        $('.navbar-collapse').collapse('hide');
    });
  },

  methods: {
      ...mapActions({ logout: 'logoutABC'}),

      handleSelect(key, keyPath) {
        console.log(key, keyPath);
      },

      hideModal() {
        this.dialogVisible = false
      },
  }
}
</script>

<style lang="scss">
.footer-text p.small {
  font-size: 70%;
}
a {
  color: inherit;
}
#app {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
} 
.signup-modal .el-dialog__body .el-button{
  padding: 20px;
  border-radius: 0;
  font-size: 16px;
}
.footer {
  text-align: center;
  background-color: transparent;
  padding-top: 50px;
  padding-bottom: 20px;
}
.footer h5{
  margin: 20px 0 35px 0;
}
.icon-user {
  border: 1px solid gray;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  padding: 0 4px;
}
.icons {
  height: 30px;
  width: 30px;
}
.user-nav {
  .el-dropdown-link {
    cursor: pointer;
  }
}
.el-button--success {
  color: #FFF;
  background-color: #91C145;
  border-color: #91C145;
}
.footer ul li{
  list-style: none;
   padding: 5px 0;
   font-size: .9rem;
}
.footer ul {
  padding-left: 0;
}
.navbar {
  z-index: 2003;
  width: 100%;
}
.navbar .navbar-toggler:focus, .navbar .navbar-toggler:active {
  outline: 0;
}

.el-menu--popup>.el-menu-item:hover, .el-menu--popup .el-menu-item:hover,.el-menu--popup .dropdown-item:focus,.el-menu--popup .dropdown-item:hover{
  background-color: none !important;
  background: transparent;
}
.dropdown-item.active, .dropdown-item:active {
  background-color: none !important;
  background-color: transparent;
  color: #212529;
}
.nav-item .dropdown-item {
  font-size: 14px;
  color: #606266 !important;
  letter-spacing: 1px;
  padding: 8px 20px;
}
.nav-item .dropdown-menu {
  border: none;
  border-radius: 0;
}
.transparent-dropdown .dropdown-menu {
  background-color: rgba(249, 249, 247, 0.88);
}
#app .navbar-light .navbar-nav .nav-link:hover {
  color: #E87722;
}
.nav-item .dropdown-item:focus, .dropdown-item:hover {
  color: #E87722 !important;
  background-color: transparent;
}
.el-menu--horizontal.menu-2 {
  display: inline;
  outline: 0 !important;
}
nav.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.no-shadow.scrolled {
  background: rgba(249, 249, 247, 0.88) !important;
  transition: all .2s ease-in;
}
@media (min-width: 768px) {
  nav.no-shadow {
    top: -15px;
  }
}
@media (max-width: 767px) {
  .no-shadow.scrolled {
    background: rgb(249, 249, 247) !important;
  }
  nav.no-shadow {
    top: 0px;
  }
  .navbar {
    padding: 0 5px;
    .navbar-brand img{
      height: 30px;
    }
    .navbar-brand {
      padding-top: 10px;
      padding-bottom: 10px;
      width: 200px;
    }
  }
  .footer {
    padding-top: 30px;
    padding-right: 30px;
    padding-left: 30px;
  }
  .footer .mb-5{
    margin-bottom: 1rem !important;
  }
  .social-footer {
    padding-top: 1rem;
    text-align: center !important;
  }
  .dropdown-toggle::after {
    display: none;
  }
  .dropdown-menu {
    display: block !important;
    padding: 0;
    margin-top: 0;
  }
}


</style>
