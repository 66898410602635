export default (codes = '') => {
    let object = {}
    object.utmCodes = codes
    object.get = {}
    
    object.getParams = () => {
        var parser = document.createElement('a')
        parser.href = object.utmCodes
        var query = parser.search.substring(1)
        var vars = query.split('&')
        
        return vars
    }

    for (var i = 0; i < object.getParams().length; i++) {
        if (/utm_\w.*/g.test(object.getParams()[i])) {
            var pair = object.getParams()[i].split('=')
            object.get[pair[0]] = decodeURIComponent(pair[1])
        }
    }

    return object
}