/**
 * URL USED FOR ENDPOINT
 * @type {string}
 */
export const LOCAL_BASE_URL = process.env.VUE_APP_API_URL;
export const DEV_BASE_URL = 'http://18.144.142.54:8080';
export const STAGING_BASE_UR = 'http://18.144.142.54:8080';
export const PRODUCTION_BASE_URL = 'http://18.144.142.54:8080';
export const API_BASE_URL = LOCAL_BASE_URL + '/api/v1';

/**
 * CREDENTIALS FOR ACCESS TOKEN
 */
export const CLIENT_SECRET = process.env.VUE_APP_PASSPORT_CLIENT_SECRET;
export const CLIENT_PASSWORD_GRANT_TYPE = process.env.VUE_APP_PASSPORT_GRANT_TYPE;
export const CLIENT_ID = process.env.VUE_APP_PASSPORT_CLIENT_ID;

/**
 * HTTP_METHOD
 */
export const POST = 'POST';
export const GET = 'GET';
export const PUT = 'PUT';
export const PATCH = 'PATCH';

/**
 * OAUTH
 */
export const OAUTH = '/api/v1/oauth';
export const OAUTH_CHANGE_PASSWORD = '/api/v1/oauth/change-password';
export const OAUTH_VERIFY_EMAIL = 'api/v1/oauth/verify/';

/**
 * FREELANCER ENDPOINT
 */
export const FREELANCER = '/api/v1/freelancer';
export const FREELANCER_UPDATE_NAME = '/api/v1/freelancer/update/profile/name';
export const FREELANCER_UPDATE_ADDRESS = '/api/v1/freelancer/update/profile/address';
export const FREELANCER_UPDATE_BIO = '/api/v1/freelancer/update/profile/bio';
export const FREELANCER_SIGNUP = '/api/v1/freelancer/register';
export const FREELANCER_SIGNUP_NEXT = '/api/v1/freelancer/register/next';

/**
 * EMPLOYER_ENDPOINT ENDPOINT
 */
export const EMPLOYER_SIGNUP = '/api/v1/employer/register';
export const EMPLOYER_SIGNUP_CO_INFO = '/api/v1/employer/register/next/company-info';
export const EMPLOYER_SIGNUP_BILLING_INFO = '/api/v1/employer/register/next/billing-info';
export const EMPLOYER_SIGNUP_INVITE = '/api/v1/employer/register/next/invite';
export const EMPLOYER_SIGNUP_UPLOAD_LOGO = '/api/v1/employer/register/next/upload-logo';

export const EMPLOYER_UPDATE_SIGNUP = '/api/v1/employer/update/next';
export const EMPLOYER_UPDATE_SIGNUP_CO_INFO = '/api/v1/employer/update/next/company-info';
export const EMPLOYER_UPDATE_SIGNUP_BILLING_INFO = '/api/v1/employer/update/next/billing-info';
export const EMPLOYER_UPDATE_SIGNUP_INVITE = '/api/v1/employer/update/next/invite';
export const EMPLOYER_UPDATE_SIGNUP_UPLOAD_LOGO = '/api/v1/employer/update/company-logo';
export const EMPLOYER = '/api/v1/employer';
export const EMPLOYER_BOOKMARKED_FREELANCERS = '/api/v1/employer/bookmarked-freelancers';
/**
 * FREELANCER_COMPANY ENDPOINT
 */
export const FREELANCER_COMPANY_SIGNUP = '/api/v1/freelancer-company/register';
export const FREELANCER_COMPANY_SIGNUP_NEXT = '/api/v1/freelancer-company/register/next';
export const FREELANCER_COMPANY = '/api/v1/freelancer-company';

/**
 * SUBSCRIPTION ENDPOINT
 */
export const BILLING_TOKEN = '/api/v1/payment/token';
export const SUBSCRIBE = '/api/v1/payment/subscribe';
export const FREE_SUBS_COUNT = '/api/v1/employer/get/free-subscriptions';

/**
 * JOBS
 */
export const JOBS = '/api/v1/freelancer/jobs';
export const JOBS_LIST = '/api/v1/jobs/list';

/**
 * Messenger
 */
export const CONVERSATIONS = API_BASE_URL + '/conversations';
export const MESSAGES = '/api/v1/message';

export const SKILLS = '/api/v1/skills';

/**
 * Localstorage Details
 */
export const USER = JSON.parse(localStorage.getItem('user'));

/**
 * User skill limit allowed
 */
export const SKILL_LIMIT = 15;
/**
 * Default rate value
 */
export const DEFAULT_RATE = 0;
