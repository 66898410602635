// store.js
import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getter';
import actions from './action';
import mutations from './mutation';
import messenger from '~/store/modules/messenger';
import subscription from '~/store/modules/subscription';

Vue.use(Vuex);

const state = {
  freelancerId: localStorage.getItem('freelancer_id'),
  freelancer: {
    provider: 'freelancer',
    first_name: '',
    last_name: '',
    email: '',
    profile_photo: '',
    info: '',
    errorResp: '',
    successResp: '',
    profile: {
      title: '',
      intro: '',
      description: '',
      address_1: '',
      address_2: '',
      state: '',
      zip: '',
      country: '',
      full_time_ava: 0,
      full_time_price: 0.0,
      part_time_ava: 0,
      part_time_price: 0.0,
      hourly_ava: 0,
      hourly_price: 0.0
    },
    password: '',
    old_password: '',
    password_confirmation: '',
    infoError: '',
    infoSuccess: '',
    detailSuccess: false,
    passwordSuccess: false,
    bioSuccess: false,
    addressError: null,
    addressSuccess: null,
    billingError: null,
    billingResp: null,
    progress: false
  },
  employer: {
    first_name: '',
    last_name: '',
    email: '',
    company: '',
    billing_information: '',
    errorRespO: {},
    successRespO: {},
    billingErrorO: '',
    billingResp: '',
    coError: '',
    cogResp: '',
    passwordSuccess: false,
    profileSuccess: false
  },
  employerProfileActions: {
    isLoading: false,
    isSuccess: false
  },
  presentationError: null,
  presentationSuccess: null,
  employerErr: '',
  employerErrCmpProfileErr: '',
  employerSuccess: '',
  jobs: {
    show: '',
    list: '',
    error: {
      bookmarked: '',
      application: null
    },
    success: {
      bookmarked: null,
      application: null
    },
    // application:'',
    bookmarked: {
      data: null
    },
    applied: {
      data: null
    }
  },
  applicationAttachment: {},
  jobApplicationErr: null,
  jobApplicationSuccess: null,
  jobApplicationOnLoading: null,
  jobApplicationOnUploading: null,
  jobError: '',
  jobSuccess: '',
  freelancerCompany: {
    information: null
  },
  apiError: {
    check_password: ''
  },
  apiSuccess: null,
  loading: false,
  disable: false,
  enable: false,
  agreement: true,
  auth: localStorage.getItem('user') || '',
  status: '',
  authError: '',
  transactions: [],
  isLoaded: false,
  passwordForgot: {
    error: '',
    success: ''
  },
  passwordReset: {
    error: false,
    success: false,
    response: ''
  },
  country: {
    country_code: '',
    country_name: '',
    region_code: '',
    region_name: '',
    city: '',
    zip_code: '',
    time_zone: '',
    latitude: '',
    longitude: '',
    metro_code: ''
  },
  contact: {
    error: null,
    success: null
  },
  concierge: {
    member_category: 'employer',
    first_name: '',
    last_name: '',
    email: '',
    company_name: '',
    company_website: '',
    phone: '',
    message: '',
    referrer: '',
    error: null,
    success: null
  },
  citronworkers: {
    member_category: 'employer',
    first_name: '',
    last_name: '',
    email: '',
    company_name: '',
    company_website: '',
    phone: '',
    message: '',
    referrer: '',
    error: null,
    success: null,
    agreement: false,
    concierge_subscribe: false,
    successMessage: null
  },
  presentation: {
    member_category: 'employer',
    first_name: '',
    last_name: '',
    email: '',
    company_name: '',
    company_website: '',
    phone: '',
    concierge_subscribe: false,
    agreement: false,
    error: null,
    success: null
  },
  conciergeError: null,
  citronworkersError: null,
  presentationErr: null,
  freeSubscriptionCount: null,
  employerBookmarkedFreelancers: [],
  emailVerifyResp: null,
  emailVerifyErr: null,
  inviteUserResp: null,
  inviteUserErr: null,
  inviteUserForm:{
    first_name: '',
    last_name: '',
    email: '',
    role: null
  }
};

export default new Vuex.Store({
  state,
  mutations,
  getters,
  actions,
  modules: { messenger, subscription }
});
