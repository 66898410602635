import moment from 'moment'

export default type => {
  const key = `${type}:signup`

  // get signup details from local storage
  let signUpDetails = localStorage.getItem(key)

  // if none is set, return null
  if (! signUpDetails) {
    return null
  }

  // turn json string into object
  signUpDetails = JSON.parse(signUpDetails)

  // get the current unix time
  const unixNow = moment().unix()

  // check if the signup is expired. if it is, remove the item
  // signups expires in 2 hours
  if (signUpDetails.expiry < unixNow) {
    localStorage.removeItem(key)
    return null
  }

  // if all is good (not expired; not empty), return the signUpDetails
  return signUpDetails
}
