import Vue from 'vue'
import VueRouter from 'vue-router'
import defaultRoutes from './router/index'
import { RefCookie } from './modules/cookies'

Vue.use(VueRouter)

const router = new VueRouter({
  routes: defaultRoutes,
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash }
    }
    if (savedPosition) {
      return savedPosition
    }
    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, from, next) => {
  RefCookie(to, from)
  next()
})

export default router
