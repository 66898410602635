import Conversation from '~/models/Conversation'
import getUserType from '~/helpers/getUserType'
import getUserId from '~/helpers/getUserId'

window.Pusher = require('pusher-js')

export default {
  namespaced: true,

  state: {
    conversations: [],
    Echo: null,
  },

  getters: {
    unread: state => state.conversations.filter(c => c.current_participant.num_unread_messages > 0),
    numUnread: (state, getters) => getters.unread.length,
  },

  mutations: {
    set (state, { key, value }) {
      state[key] = value
    },

    read (state, conversationId) {
      const conversation = state.conversations.find(c => c.conversation_id === parseInt(conversationId))
      if (conversation) {
        conversation.current_participant.num_unread_messages = 0
      }
    },
  },

  actions: {
    initialize ({ state, commit, dispatch }) {
      // if already initialized, don't re-initialize
      if (state.Echo) {
        return
      }

      // fetch initial unread conversations
      dispatch('fetchConversations')

      // initialize Echo
      commit('set', { key: 'Echo', value: Conversation.Echo() })

      // listen for changes in user's unread-conversations
      state.Echo.private('notifications.' + getUserType() + '.' + getUserId())
        .notification(notification => {
          if (notification.type === 'UnreadConversationsUpdated') {
            dispatch('fetchConversations')
          }
        })
    },

    // fetch current unread conversations
    fetchConversations ({ commit }) {
      Conversation.all()
        .then(response => commit('set', { key: 'conversations', value: response.data }))
    },
  },
}
