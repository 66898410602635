import Echo from 'laravel-echo'
import store from '~/vuex/store'
import { API_BASE_URL } from '~/vuex/utils/constant'
import getUserType from '~/helpers/getUserType'
import Model from '~/models/Model'
import Router from '~/router.js'
import handleError from '../helpers/handleError'

export default class Conversation extends Model {
  constructor (id) {
    super(id)

    // initialize the messenger store if not yet initialized
    store.dispatch('messenger/initialize')

    // build the Echo instance
    this.Echo = store.state.messenger.Echo

    this.channelName = `conversation.${id}`

    // mark the conversation as read
    store.commit('messenger/read', id)

    // join the channel
    this.channel = this.Echo.join(this.channelName)
  }

  static Echo () {
    return new Echo({
      broadcaster: 'pusher',
      key: process.env.VUE_APP_PUSHER_APP_KEY,
      cluster: process.env.VUE_APP_PUSHER_CLUSTER,
      encrypted: true,
      authEndpoint: this.baseURL('auth'),
      auth: { headers: this.headers() },
    })
  }

  static get resource () {
    return 'conversations'
  }

  static visit() {
    return location.href = '/' + this.resource
  }

  static baseURL (path = '') {
    return API_BASE_URL + '/messenger/' + getUserType() + '/conversations/' + path
  }

  static create (memberId, memberType) {
    return this.axios().post('/', {
      member_id: memberId,
      member_type: memberType,
    })
  }

  static createAndRedirect (memberId, memberType) {
    this.create(memberId, memberType)
      .then(response => Router.push(`/conversations/${response.data.conversation_id}`))
      .catch(handleError)
  }

  sendMessage (message) {
    return this.axios.post('messages', { message }, {
      headers: { 'X-Socket-Id': this.Echo.socketId() },
    })
  }

  getMessages (page) {
    return this.axios.get('messages', { params: { page } })
  }

  leave () {
    this.Echo.leave(this.channelName)
  }
}
