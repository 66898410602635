import Employer from '~/models/Employer'
import getUserType from '~/helpers/getUserType'

export default {
  namespaced: true,

  state: {
    name: '',
  },

  getters: {
    isOnFreePlan (state) {
      return state.name === 'free'
    },
  },

  mutations: {
    set (state, value) {
      state.name = value
    },
  },

  actions: {
    checkSubscriptionName ({ state, commit }, refresh = false) {
      if (getUserType() === 'freelancer') {
        // if freelancer, do nothing
        return
      }

      if (! state.name || refresh) {
        Employer.getSubscriptionName()
          .then(response => commit('set', response.data))
      }
    },
  },
}
