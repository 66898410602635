<template>
<div class="signup employer">
  <div class="signup-body">
    <div class="container">
      <div class="row justify-content-md-center">
        <!-- <div class="text-center col-12 col-md-8 col-lg-5 pt-5 pt-lg-0 pr-3 pr-md-5 align-self-center">
          
        </div> -->
        <div class="content mt-3 col-md-8 col-lg-6 p-0">
          <hr>
          <div class="form beta">
            <el-form ref="form" :model="signup">
              <el-row :gutter="20">
                <el-col :xs="24" :md="20" style="float: none; margin: 10px auto 0;">
                  <!-- <img src="/images/comingsoon.png" style="display: block; max-width: 300px; width: 100%; margin: 0 auto;"/> -->
                  <h6 class="mt-4 mb-4 orange-text text-center">We are currently invite-only. Please leave your name and email and we will send you an invite when you can join.</h6>
                </el-col>
                
                <!-- <el-col class="toggle-btns mt-4" :span="24">
                  <h4 class="orange-text mb-3 bold">Freelancer Signup</h4>
                  <p style="color: #333;" class="mb-4">We are launching end of June, signup here to get an invite so you can onboard once we open.</p>
                </el-col> -->
                <el-col :xs="24" :md="12">
                  <el-form-item label="First Name" prop="first_name"
                    :rules="[
                        { required: checkingRequired(), message: 'First name is required'},
                      ]">
                    <el-input v-model="signup.first_name"></el-input>
                  </el-form-item>

                </el-col>
                <el-col :xs="24" :md="12">
                  <el-form-item label="Last Name" prop="last_name"
                      :rules="[
                        { required: checkingRequired(), message: 'Last name is required'},
                      ]">
                    <el-input v-model="signup.last_name"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :md="24">
                  <el-form-item label="Email Address" prop="email"
                      :rules="[
                        { required: checkingRequired(), message: 'Email address is required'},
                        { type: 'email', message: 'Please input correct email address'}
                      ]">
                    <el-input v-model="signup.email" type="email"></el-input>
                  </el-form-item>

                </el-col>
                <!-- <el-col :xs="24" :md="12">
                  <el-form-item label="Company" prop="company">
                    <el-input v-model="signup.company_name"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :md="12">
                  <el-form-item label="Company Website" prop="company_website">
                    <el-input v-model="signup.company_url"></el-input>
                  </el-form-item>
                </el-col> -->
                <!-- <el-col :xs="24" :md="12">
                  <el-form-item label="Phone" prop="phone">
                    <el-input v-model="signup.phone"></el-input>
                  </el-form-item>
                </el-col> -->
                
                <el-col :xs="24">
                  <p class="small">* Required</p>
                </el-col>
                <el-col :xs="24">
                  <el-form-item class="text-center buttons mt-1">
                    <el-button v-if="signup.agreement === true && signup.concierge_subscribe !== true" type="warning" @click="inviteSignUp(signup)">
                      Submit
                      <i class="el-icon-loading" v-show="status === 'loading'"></i>
                    </el-button>
                    <el-button v-if="signup.agreement === true && signup.concierge_subscribe === true" type="warning" @click="conciergeRequest(signup),referer()">
                      Submit
                      <i class="el-icon-loading" v-show="status === 'loading'"></i>
                    </el-button>
                    <el-button type="success" @click="submitForm()" :disabled="canSend != 1">
                      Submit
                    </el-button>
                    <br>
                    <div  class="text-center spinner-border text-success spinner-border-sm" :style="{visibility: canSend ? 'hidden' : 'visible'}" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>

                  </el-form-item>
                </el-col>
                <!--Show only when submitted-->
                 <el-col :xs="24">
                  <el-alert v-if="status ==='success'" class="mb-3 mt-3"
                    :title="signup.successMessage"
                    type="success">
                  </el-alert>
                </el-col>
                <!--Show when there's an error-->
                <el-col :xs="24">
                <el-alert v-if="error"
                    title="The following is required:"
                    type="error"
                    :closable="click=false"
                    @close="true"
                    class="signup-alert mb-3 mt-3">
                    <li v-for="first in error.first_name"
                        :key="first">
                      <i class="el-icon-error"></i> {{ first }}
                    </li>
                    <li v-for="last in error.last_name"
                        :key="last">
                      <i class="el-icon-error"></i> {{ last }}
                    </li>
                    <li v-for="email in error.email"
                        :key="email">
                      <i class="el-icon-error"></i> {{ email }}
                    </li>
                  </el-alert>
                <el-alert v-if="reqStatus"
                    type="success"
                    :closable="click=false"
                    @close="true"
                    class="signup-alert mb-3 mt-3">
                    <div class="text-center">
                      <i class="el-icon-success"></i> {{reqStatus.message}}
                    </div>
                  </el-alert>

                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';
  // import TermsOfService from '~/components/Terms/TermsOfService'
  // import PrivacyPolicy from '~/components/Terms/PrivacyPolicy'

  export default {
    components: {
      // TermsOfService,
      // PrivacyPolicy,
    },
    data() {
      return {
        tos: false,
        pp: false,
        // signup: {
        //   member_category: this.$route.path.split('/')[1],
        //   first_name: '',
        //   last_name: '',
        //   email: '',
        //   company_name: '',
        //   company_website: '',
        //   phone: '',
        //   agreement:false
        // },
        errors: {},
        canSend:1
      }
    },
    created: function() {
        this.$store.state.inviteUserForm.role = this.$route.query.role
    },
    computed: {
      ...mapGetters({
         error: 'getInviteRequestError',
         reqStatus: 'getInviteRequestResponse',
         signup: 'getInviteRequestForm',
         conciergeErr: 'getConciergeErr',
         status: 'authStatus'
      }),

    },
    // watch: {
    //   '$route.path': function (newValue) {
    //     this.signup.member_category = newValue.split('/')[1]
    //   },
    // },
    methods: {
      ...mapActions({
        conciergeRequest: 'conciergeRequest',
        inviteSignUp: 'inviteSignup',
      }),
      checkingRequired() {
        return this.status === 'success' ? false : true;
      },
      referer() {
        return this.signup.referer = 'Employers form';
      },
      async submitForm(){
        this.canSend=0;
        await this.inviteSignUp(this.signup);
        this.$refs.form.resetFields();
        this.canSend=1;
      }
    }
  }
</script>

<style scoped>
  h6 {
    line-height: 1.6;
    font-weight: 600;
  }
  label, .el-form-item__label{
    margin-bottom: 0;
  }
  .tab {
    padding: 0;
  }
  p.toggle:after{
    content: "*";
    color: #f56c6c;
    margin-left: 4px;
  }
  .toggle-btns ul.tab li label {
    padding: 15px 20px;
    width: 200px;
    font-size: 14px;
    border: 1px solid #DCDFE6;
  }
  .signup .form-term {
    margin-bottom: 0;
  }
  .signup .form-term>>>.el-form-item__content {
    line-height: 25px;
    margin-top: 10px;
  }
  .terms {
    color: inherit;
    text-decoration: underline;
    padding: 0;
  }
  .terms:active, .terms:focus {
    outline: 0;
  }

  .form.beta h4 {
    letter-spacing: 1.1px;
  }
  @media (max-width: 767px) {
    .terms {
      font-size: 12px;
    }
  }
  @media (max-width: 575px) {
    .toggle-btns ul.tab li label {
      width: 99%;
      max-width: 100%;
      margin: 0 !important;
    }
    .toggle-btns .tab li {
      display: block !important;
      width: 100%;
      margin: 0 !important;
    }
  }
  @media (max-width: 375px){
    .signup .form-term{
      margin-bottom: 10px;
    }
  }
</style>