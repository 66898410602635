/**
 * Next we will register thePassport Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

const token = localStorage.getItem('user-token') || 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiNjNlMzQ3NjFiNGQ1NjAzMTNhODYwZGJhOTBkYWE2ZDU2NzM2ZmY1NjFkYmMwYTg0MjI1MjNlZTgwZGQ3YjM5ZTEwYWM4MjY3MjM0NjA4ZDUiLCJpYXQiOjE1ODMyMjIzODIsIm5iZiI6MTU4MzIyMjM4MiwiZXhwIjoxNjE0NzU4MzgyLCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.l-BB-WwyPt5xroqD0lrSVatpaQUPOuCFK3amgsvwM3Hky9r0VbDOlWjW2k44ZRUvZqNMg4L64FbwjPmi1AW6L9iUI-7unKx8g0Iqoulw-jXSi8I0B9J9Z33vDS39ZG3opnWjAH1GH7pgMJdt2eQrSu7wWXqfU6WW-DwNgn-tcQzyYoL6rPJu_SBn5H-R5nXWb-9-MBzjNuUac2WCCPQkinZzYZxaeyyJQlimE8RXuTH0it-Ol16eZi6Celju2vXTpf9yKAixl_QXsN0yNqseeTbGQxvC63-7QJpOTo8UludrknYKF45qfCzLk1KTGvuN1ybsN70H9Hph3Qd1U8fZfj6XoyH-X7tQs7BaNgVc0qAYGs85oB9sWlTfxTvSE_MkJd6TkjhLj_HBrAobimOdDcXoJbpdKz35uMohyncWq0yVv8tTU5vxmrQUI3X9jDLE1djlkJJYuRiMo1_ica09e0Xg4zsLpRaMNmZdJRuXlLhA5PgBu5hW_awy7__IoBpp3M-aPQ9Ns5F3LvWxp7waBSmcjHX1WxwmtL67f_RgDBAh3JRAQ86NrMzHlYyXMX2DhD-DkW347odIPq8VlZCaLp8EDkU_2V0vGgAzpAs76QuNkRuNnuaa7rjgjiWGiEnZMlyuGWERquEMs5fR1xNx37hU-GC0JuQIFG87IZU1fBA'

let axios = (function() {
    let _axios = null;
    try {
        _axios = require('axios');
    } catch (e) {
        console.error('Axios package not available');
    }

    if(_axios) {
        _axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
        _axios.defaults.headers.common['Accept'] = 'application/json';
        _axios.defaults.headers.common['Content-Type'] = 'application/json';

        if (token) {
            _axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

            // declare a response interceptor
            _axios.interceptors.response.use(
                (response) => {
                    return response;
                },
                (error) => {
                    if(error.response) {
                        if (error.response.status === 401 && error.response.config && !error.response.config.__isRetryRequest) {
                            // Ever get an unauthorized, logout the user
                            // localStorage.removeItem('user-token');
                             // delete _axios.defaults.headers.common['Authorization'];
                            // Redirect to /login if needed !
                            // Oe show a login modal
                        }
                    }

                    // handle the response error
                    return Promise.reject(error);
                }
            );

        } else {
            // do something
            // Break the site
        }
    }

    return _axios;
})();

export default axios;
