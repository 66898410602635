import store from '../store'
import getUserType from '~/helpers/getUserType'

const userFeedPath = () => {
  const userType = getUserType()

  if (userType === 'freelancer') {
    return '/feed'
  } else if (userType === 'employer') {
    return '/employer/feed'
  } else {
    return '/'
  }
}

const freelancer = (to, from, next) => {
  if (getUserType() === 'freelancer') {
    next()
  } else {
    next(userFeedPath())
  }
}

const employer = (to, from, next) => {
  if (getUserType() === 'employer') {
    next()
  } else {
    next(userFeedPath())
  }
}

const guest = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
  } else {
    next(userFeedPath())
  }
}

const authorize = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next()
    return
  }
  next('/login')
}

export { guest, authorize, employer, freelancer, userFeedPath }
