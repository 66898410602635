import 'babel-polyfill'
import Vue from 'vue'
import Meta from 'vue-meta'
import './plugins/element.js'
import App from './App.vue'
import 'whatwg-fetch'
import VueSocialSharing from 'vue-social-sharing'
import router from './router'
import 'vue-trix'
require('canvas-toBlob')
// import { RefCookie } from './modules/cookies';
import "vue-trix";
import cleaveDirective from './directives/cleave';

Vue.use(Meta)
Vue.use(require('vue-scrollto'))
Vue.use(VueSocialSharing)

Vue.config.productionTip = false

window.Popper = require('popper.js').default
window.$ = window.jQuery = require('jquery')

Vue.directive('cleave', cleaveDirective);

require('bootstrap')

new Vue({
  router,
  // store,
  render: h => h(App),
}).$mount('#app')
